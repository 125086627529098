import { Component, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-banner-home',
  templateUrl: './btr-appbanner-home.component.html',
  styleUrls: ['./btr-appbanner-home.component.css']
})
export class BtrBannerHomeComponent implements OnInit {

  constructor(private deviceService: DeviceDetectorService) { }
  public isAndroidDetected = true;
  public isIosDeviceDetected = true;
  public deviceInfo = null;

  ngOnInit() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    const osInfo = this.deviceInfo.os.toLowerCase();
    if ((this.deviceInfo.deviceType === 'mobile' || this.deviceInfo.deviceType === 'tablet') && osInfo === 'android') {
      // show only android button
      this.isIosDeviceDetected = false;
    }
    if ((this.deviceInfo.deviceType === 'mobile' || this.deviceInfo.deviceType === 'tablet') && osInfo === 'ios') {
      // show only ios button
      this.isAndroidDetected = false;
    }
  }
}
