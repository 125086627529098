<!-- The Modal -->
<div id="privacyMessageEN" class="modal">
  <div id="loaderMessageEN" class="modal-loader">
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; background: transparent; display: block" width="50px" height="50px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
      <circle cx="50" cy="50" r="32" stroke-width="8" stroke="#ef2637" stroke-dasharray="50.26548245743669 50.26548245743669" fill="none" stroke-linecap="round">
        <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" keyTimes="0;1" values="0 50 50;360 50 50"></animateTransform>
      </circle>
    </svg>
    <p>Your virtual assistant is on its way!</p>
  </div>
  <!-- Modal content -->
  <div class="modal-content">
    <span class="modal-title">Hello 👋</span>
    <p class="privacy-1">
      By using our chat, you agree to the processing of your personal data by bpost in connection with your questions.
    </p>
    <p class="privacy-2">
      For more information, please consult <a style="color: #ef2637" href="https://www.bpost.be/en/privacy" target="_blank">our confidentiality policy</a>.
    </p>
    <p class="warning">
      <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
        <!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
        <style>
          svg {
            fill: #ed7125;
          }

        </style>
        <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-384c13.3 0 24 10.7 24 24V264c0 13.3-10.7 24-24 24s-24-10.7-24-24V152c0-13.3 10.7-24 24-24zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z" />
      </svg>
      Unfortunately, private mode does not allow our chat to function properly. Open the chat in a regular window.
    </p>
    <button id="privacyCloseEN" type="button" class="close-button-style" title="Leave chat">Leave chat</button>
    <button id="privacyAcceptEN" type="button" class="button-style" title="Start chat">
      Start chat
    </button>
  </div>
</div>
<div id="privacyMessageNL" class="modal">
  <div id="loaderMessageNL" class="modal-loader">
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; background: transparent; display: block" width="50px" height="50px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
      <circle cx="50" cy="50" r="32" stroke-width="8" stroke="#ef2637" stroke-dasharray="50.26548245743669 50.26548245743669" fill="none" stroke-linecap="round">
        <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" keyTimes="0;1" values="0 50 50;360 50 50"></animateTransform>
      </circle>
    </svg>
    <p>Je virtuele assistent komt er zo aan</p>
  </div>
  <!-- Modal content -->
  <div class="modal-content">
    <span class="modal-title">Hallo 👋</span>
    <p class="privacy-1">
      Door onze chat te gebruiken, gaat u ermee akkoord dat bpost uw persoonlijke gegevens verwerkt in functie van uw vragen.
    </p>
    <p class="privacy-2">
      Raadpleeg <a style="color: #ef2637" href="https://www.bpost.be/nl/privacy" target="_blank">ons privacybeleid</a> voor meer informatie.
    </p>
    <p class="warning">
      <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
        <!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
        <style>
          svg {
            fill: #ed7125;
          }

        </style>
        <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-384c13.3 0 24 10.7 24 24V264c0 13.3-10.7 24-24 24s-24-10.7-24-24V152c0-13.3 10.7-24 24-24zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z" />
      </svg>
      Helaas werkt onze chat niet goed in privémodus. Open de chat in een normaal venster.
    </p>
    <button id="privacyCloseNL" type="button" class="close-button-style" title="Chat verlaten">Chat verlaten</button>
    <button id="privacyAcceptNL" type="button" class="button-style" title="Chat starten">Chat starten</button>
  </div>
</div>
<div id="privacyMessageFR" class="modal">
  <div id="loaderMessageFR" class="modal-loader">
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; background: transparent; display: block" width="50px" height="50px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
      <circle cx="50" cy="50" r="32" stroke-width="8" stroke="#ef2637" stroke-dasharray="50.26548245743669 50.26548245743669" fill="none" stroke-linecap="round">
        <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" keyTimes="0;1" values="0 50 50;360 50 50"></animateTransform>
      </circle>
    </svg>
    <p>Votre assistant virtuel arrive !</p>
  </div>
  <!-- Modal content -->
  <div class="modal-content">
    <span class="modal-title">Bonjour 👋</span>
    <p class="privacy-1">
      En utilisant notre chat, vous donnez votre accord pour le traitement de vos données personnelles par bpost dans le cadre de vos questions.
    </p>
    <p class="privacy-2">
      Pour plus d'informations, consultez
      <a style="color: #ef2637" href="https://www.bpost.be/fr/privacy" target="_blank">notre politique de confidentialité</a>.
    </p>
    <p class="warning">
      <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
        <!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
        <style>
          svg {
            fill: #ed7125;
          }

        </style>
        <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-384c13.3 0 24 10.7 24 24V264c0 13.3-10.7 24-24 24s-24-10.7-24-24V152c0-13.3 10.7-24 24-24zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z" />
      </svg>
      Malheureusement, la navigation privée empêche notre chat de fonctionner correctement. Ouvrez-le dans une fenêtre classique.
    </p>
    <button id="privacyCloseFR" type="button" class="close-button-style" title="Quitter le chat">Quitter le chat</button>
    <button id="privacyAcceptFR" type="button" class="button-style" title="Lancer le chat">Lancer le chat</button>
  </div>
</div>
