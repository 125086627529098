<ng-container *ngIf="visibleBlock==1">
  <div class="row secure-code-long-note">
    <div class="col-xs-12">
      <p class='step-strong' [innerHTML]="'secureDelivery.infoBlock.title' | translate"></p>
      <p class="content-secure-code-note" [innerHTML]="'secureDelivery.infoBlock.info' | translate"></p>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="visibleBlock==2 || visibleBlock==3">
  <div class="row secure-code-note-parent">
    <div class="col-xs-12 secure-code-note-block">
      <img src="/assets/images/caution.png" />
      <p class="content-secure-code-note" [innerHTML]="informationContent"></p>
    </div>
  </div>
</ng-container>
