import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from '../core/services/common.service';
import { ItemsService } from '../core/services/items.service';
import { LoaderService } from '../core/services/loader.service';
import { TnTModule } from '../shared/enums/tnt-module';
import { ViewMode } from '../shared/enums/view-mode';
@Component({
  selector: 'app-btr-search',
  templateUrl: './btr-search.component.html',
  styleUrls: ['./btr-search.component.css'],
  providers: [ItemsService]
})
export class BtrSearchComponent implements OnInit {

  public module = TnTModule.BTR;
  public viewMode = ViewMode.DETAILS;
  public loading: boolean;
  public language: string;
  public items: any[];
  public filteredItems: any[];
  public nrOfItems = 0;
  public itemIdentifier: string;

  private translations: any;
  private translationsString: string;
  private postalCode: string;
  private itemId: number | string;
  private enc: boolean;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private loader: LoaderService,
    private commonService: CommonService,
    private itemsService: ItemsService) { }

  ngOnInit(): void {
    this.loading = true;
    this.loader.show();

    this.route.queryParams.subscribe(queryParams => {
      this.language = localStorage.getItem('lang');

      if (localStorage.getItem('SelectedItem') !== null) {
        localStorage.removeItem('SelectedItem');
      }

      if (localStorage.getItem('MultipleItems') !== null) {
        localStorage.removeItem('MultipleItems');
      }

      if (localStorage.getItem('translations_' + localStorage.getItem('lang').toLowerCase()) !== null) {
        localStorage.removeItem('translations_' + localStorage.getItem('lang').toLowerCase());
      }

      if (queryParams && queryParams.lang) {
        this.itemsService.storeData();
        this.commonService.setLocalStorage(queryParams.lang.toLowerCase());
        this.language = localStorage.getItem('lang');
        this.translateService.use(this.language);
      }

      // URL Parameters are not valid then go to Home.
      if (!queryParams && (!queryParams.itemCode || !queryParams.itemCode2)) {
        this.commonService.backToHome(this.module);
      }
      if (queryParams.itemCode2) {
        this.enc = true;
      }
    });

    this.itemIdentifier = sessionStorage.getItem('itemCode');
    this.postalCode = sessionStorage.getItem('postalCode');

    this.route.queryParams.subscribe(params => {
      this.itemIdentifier = params.itemCode || params.itemCode2;
      this.postalCode = params.postalCode;
      this.itemId = params.itemId;
      this.nrOfItems = 0;

      this.callAPI();
    });
  }

  public goHome(): void {
    this.commonService.backToHome(this.module);
  }

  private callAPI(): void {
    this.translationsString = localStorage.getItem('translations_' + this.language);
    if (this.translationsString != null) {
      this.translations = JSON.parse(this.translationsString);
      this.getItems();
    } else {
      this.itemsService.getTranslations(this.language).subscribe(
        (data) => {
          localStorage.setItem('translations_' + this.language, JSON.stringify(data));
          this.translations = data;
          this.getItems();
        }
      );
    }
  }

  private getItems(): void {
    this.itemsService.getItemByBarcode(this.itemIdentifier, this.postalCode, this.enc).subscribe(
      (data) => {
        this.loading = false;
        this.loader.hide();
        if (data.error) {
          this.router.navigate(['home'], {
            queryParams: { lang: localStorage.getItem('lang'), validationStack: data.error, itemCode: this.itemIdentifier, postalCode: this.postalCode }
          });
        } else {
          this.items = data.items;
          this.nrOfItems = this.items.length;
          this.items = this.commonService.processItemsData(this.items, this.language, this.translations, this.module);
          if (this.items.length === 0) {
            this.router.navigate(['home'], {
              queryParams: { lang: localStorage.getItem('lang'), validationStack: 'emptyResponse' }
            });
          }
          if (this.items.length === 1) {
            localStorage.setItem('SelectedItem', JSON.stringify(this.items));
            this.filteredItems = this.items;
          } else {
            this.filteredItems = [];
            localStorage.setItem('MultipleItems', JSON.stringify(this.items));
          }

          if (this.items.length > 0 && this.itemId) {
            this.filterbyItemId(this.itemId);
          }
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  private filterbyItemId(itemId: number | string): void {
    this.filteredItems = [];
    this.filteredItems = this.items.filter((newItem, index) => {
      return String(newItem.key.id) === String(itemId);
    });
    if (this.filteredItems && this.filteredItems.length === 1) {
      localStorage.setItem('SelectedItem', JSON.stringify(this.filteredItems));
      localStorage.setItem('selectedItemCode', this.filteredItems[0].itemCode);
      this.nrOfItems = 1;
      this.viewMode = ViewMode.SUMMARY;
      if (this.postalCode) {
        this.router.navigate(['search'], {
          queryParams: {
            lang: localStorage.getItem('lang'), itemCode: this.itemIdentifier,
            postalCode: this.postalCode, itemId: this.filteredItems[0].key.id
          }
        });
      } else {
        this.router.navigate(['search'], {
          queryParams: { lang: localStorage.getItem('lang'), itemCode: this.itemIdentifier, itemId: this.filteredItems[0].key.id }
        });
      }
    } else {
      this.commonService.backToHome(this.module);
    }
  }
}
