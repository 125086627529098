// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // apiUrl: "https://trackapi-ac2.bpost.cloud",
  apiUrl: '//track-ac2.bpost.cloud',
  // apiUrl: "http://localhost:3010",
  // apiUrl: "//trackapi-dv2.bpost.cloud",
  // apiUrl: "//trackapi-ac2.bpost.cloud",
  // apiUrl: "//trackapi.bpost.cloud",
  // apiUrl: "//trackapi.bpost.be",
  // faqBaseURL: "//www-2.acbpost.be/",
  footerBaseURL: '//www-2.acbpost.be/', // ac2 is not working from nbw side, so we are pointing to ac1.
  faqURLSubString: '/site/applications/track/',
  chatBotURL: '/itt-chatbot', // Do not remove https protocol
  chatBot: {
    src: 'https://oc-cdn-public-eur.azureedge.net/livechatwidget/scripts/LiveChatBootstrapper.js',
    appId: {
      en: 'eeb10b74-98e6-4d8a-b953-34b09e58006b',
      fr: '09d22dfb-a70d-4eb0-a129-e0585f603507',
      nl: '2f6ae640-fc91-4a3c-8734-ae1d63426379',
    },
    customization: {
      en: 'https://stacrmacwemktcdnlrs.azureedge.net/bot/webchat_styles_EN.json',
      fr: 'https://stacrmacwemktcdnlrs.azureedge.net/bot/webchat_styles_FR.json',
      nl: 'https://stacrmacwemktcdnlrs.azureedge.net/bot/webchat_styles_NL.json',
    },
    lcwVersion: 'prod',
    orgId: 'd1681e22-104d-45a6-88a8-de63b85de0ab',
    orgUrl: 'https://unqd1681e22104d45a688a8de63b85de-crm4.omnichannelengagementhub.com'
  },
  drupalBaseURL: '//nbw-ac2.bpost.cloud/', // ac2 is not working from nbw side, so we are pointing to ac1.
  googleMapsLink: '//www.google.com/maps/place/',
  // captchaSiteKey: "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI", // Test Captcha
  captchaSiteKey: '6LdGIMMUAAAAAP4elHqofHP16OqgE__mZTaSfJFB', // *.bpost.cloud Captcha
  // captchaSiteKey: "6Ldq6QsTAAAAAPHEV8on2Sj1g41jtZAAWR7jie_-", // *.bpost.be Captcha
  // deliveryPrefURL: "https://track.bpost.be/preferences/?language=",
  usabillaKeys: [{ en: '5b8f96e44667904c5b7d30ad' }, { fr: '5b8e59e346679013030fdfee' }, { nl: '5b8e5cb3466790241e571205' }],
  faqId: '1851',
  googlePlayStorePackageCode: 'com.bpost.packami_droid',
  // bpostURL: "//www.bpost.be/",
  barCodeImage: 'sites/default/files/complete_html/P_NL.jpg',
  trackBpostURL: '//track-2.acbpost.be/',
  lmgBaseUrl: '//landmarkglobal.com/',
  gtmId: 'GTM-WJ4RB5',
  apiCryptoKey: '/404b1967-6507-45ab-8a6d-7374a3f478be/',
  itemonroundstatus_time: 5000
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
