<!-- Parcel details Heading and Subheading-->
<div class="wrapper center-block">
  <div class="panel panel-default">
    <div class="panel-heading active" role="tab" id="headingOne">
      <h4 class="panel-title parcel-details-block">
        <a role="button" (click)="expandIcon()" data-toggle="collapse" data-parent="#accordion"
           href="#parcel_detail" aria-expanded="false" aria-controls="collapse" class="collapsed">
          <i class="more-less glyphicon glyphicon-plus"></i>
          <p class="panel-parent-div">{{parcelDetailsHeading}}</p>
          <p class="panel-sub-div">{{moreInfoText}}</p>
        </a>
      </h4>
    </div>
    <!-- Parcel details body -->
    <div id="parcel_detail" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne">
      <div class="panel-body">
        <p class="cust_ref" *ngIf="item.itemCode">
          <span><strong>{{'parcel_details.barcode_bpost' | translate}}</strong></span>
          {{item.itemCode}}
        </p>
        <p class="cust_ref" *ngIf="item.senderBarcode">
          <span><strong>{{'parcel_details.barcode_sender' | translate}}</strong></span>
          {{item.senderBarcode}}
        </p>
        <p class="cust_ref"><span *ngIf="item.customerReference"><strong>
              {{'parcel_details.customer_reference' | translate }}</strong></span>
          {{item.customerReference}}</p>
        <p class="cust_ref"><span *ngIf="item.costCenter"><strong>
              {{'parcel_details.cost_center' | translate }}</strong></span>
          {{item.costCenter}}</p>
        <p class="cust_ref"><span *ngIf="shippingMode"><strong>
              {{'parcel_details.ShippingMethod' | translate }}</strong></span>
          {{shippingMode}}</p>
        <p class="parcel_weight" *ngIf="item.weightInGrams">
          <span><strong>{{'parcel_details.weight' | translate}}</strong></span>
          {{item.weightInGrams}}g
        </p>
        <p class="parcel_dimenion" *ngIf="item.dimensionsInCm">
          <span><strong>{{'parcel_details.dimensions' | translate}}</strong></span>
          {{item.dimensionsInCm}}
        </p>
        <p class="cust_ref" *ngIf="item.retourOrBackToSender && receiverAddress">
          <span><strong>{{'parcel_details.return_address' | translate}}</strong>
            {{receiverAddress}}
          </span>
        </p>
        <p class="cust_ref" *ngIf="(!item.retourOrBackToSender) && senderAddress">
          <span><strong>{{'parcel_details.return_address' | translate}}</strong>
            {{senderAddress}}
          </span>
        </p>
        <p class="cust_ref" *ngIf="(!item.retourOrBackToSender) && receiverAddress">
          <span><strong>{{'parcel_details.destination' | translate}}</strong>
            {{receiverAddress}}
          </span>
        </p>
        <p class="cust_ref" *ngIf="item.retourOrBackToSender && senderAddress">
          <span><strong>{{'parcel_details.original_destination' | translate}}</strong>
            {{senderAddress}}
          </span>
        </p>
        <p class="cust_ref" *ngIf="item.inNetworkDate">
          <span><strong>{{'parcel_details.reception_date' | translate}}</strong></span>
          {{inNetworkDate}}
        </p>
        <p class="cust_ref"
           *ngIf="item.expectedDeliveryDate2 && item.expectedDeliveryTimeRange && item.expectedDeliveryTimeRange.day && !item.expectedDeliveryTimeRange.day2">
          <span><strong>{{'parcel_details.expected_delivery_date' | translate}}</strong></span>
          {{expectedDeliveryDate}}
        </p>
        <p class="cust_ref"
           *ngIf="item.expectedDeliveryDate2 && item.expectedDeliveryTimeRange && item.expectedDeliveryTimeRange.day && item.expectedDeliveryTimeRange.day2">
          <span><strong>{{'parcel_details.expected_delivery_date' | translate}}</strong></span>
          {{item.expectedDeliveryDate2}}
        </p>
        <p *ngIf="item.partnerDeliveryTrackingUrl && item.isfinalMileCarrier && isBpack"><span class="dynalogic-text-color"><strong>{{dynalogicText}} {{partnerName}} -
            </strong></span>
          <a href="{{item.partnerDeliveryTrackingUrl}}" target="_blank"><span style="color:#f22230"><strong>{{trackandtrace}} ></strong></span></a>
        </p>
        <p *ngIf="item.partnerDeliveryTrackingUrl && !item.isfinalMileCarrier"><span class="dynalogic-text-color"><strong>{{dynalogicText}} {{partnerName}} -
            </strong></span>
          <a href="{{item.partnerDeliveryTrackingUrl}}" target="_blank"><span>{{item.itemCode}}</span></a>
        </p>
      </div>
    </div>
  </div>
</div>
