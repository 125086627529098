<div class="custom-address-info row">
  <div class="col-xs-12 col-md-12">
    <div class="row">
      <div class="col-xs-12">
        <p class="deliver-to-pref">{{'rm-delivery-preferences.title' | translate}}</p>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12">
        <div class="deliver-to-info">
          <p [innerHTML]=rmTextTranslationLineOne></p>
          <p [innerHTML]=rmTextTranslationLineTwo></p>
        </div>
      </div>
    </div>
    <div class="row">
      <br>
    </div>

    <div class="row">
      <br>
    </div>
    <ng-container>
      <div class="row">
        <div class="custom-submit-button">
          <button id="setIndicateDeliveryPref" (click)="goToRMPrefereedDeliveryText()">{{'rm-delivery-preferences.button' | translate}}</button>
        </div>
      </div>
    </ng-container>
  </div>
</div>
