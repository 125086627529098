import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';

@Injectable()
export class LmgAuthGuard implements CanActivate {
  constructor(private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot): boolean | UrlTree {
    if (window.location.hostname.includes('landmarkglobal-group')) {
      this.router.navigate(['lmg']);
      return false;
    }
    return true;
  }

}
