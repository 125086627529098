import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from '../../../core/services/common.service';
@Component({
  selector: 'app-green-deliveries-box',
  templateUrl: './green-deliveries-box.component.html',
  styleUrls: ['./green-deliveries-box.component.css']
})
export class GreenDeliveriesBoxComponent implements OnInit {

  postalCodeTexts = '';
  title = '';
  singleItem;
  language;
  isVisibleGreenDelivery;
  constructor(
    public readonly translateService: TranslateService,
    public readonly commonService: CommonService,
  ) { }

  ngOnInit() {
    this.isVisibleGreenDelivery = true;
    this.language = localStorage.getItem('lang');
    this.singleItem = JSON.parse(localStorage.getItem('SelectedItem'));
    this.translateService.use(this.language);
    this.title = this.translateService.instant('greenDeliveriesInMyArea.title');
    const translationsLatest = JSON.parse(localStorage.getItem('translations_' + this.language));
    if (this.singleItem[0].productCategory && this.singleItem[0].greenDeliveries &&
      this.singleItem[0].greenDeliveries.postalCodeFound && this.singleItem[0].greenDeliveries.shipmentDeliveredGreenWay
    ) {
      if (this.singleItem[0].greenDeliveries.postalCodeFound === true && this.singleItem[0].greenDeliveries.shipmentDeliveredGreenWay === true
      ) {
        if (this.singleItem[0].greenDeliveries.postalCodePercentage >= 15) {
          // TRA-1571 - parcel is greenway and postal code percentage is greater than 15
          this.postalCodeTexts = this.translateService.instant('greenDeliveriesInMyArea.one').replace(/(\{\{)([\w]+)(\}\})/ig, (match, group1, group2, group3) => this.commonService.translateFinal(group2, this.singleItem[0], translationsLatest));
          this.postalCodeTexts += this.translateService.instant('greenDeliveriesInMyArea.two', { postalCodePercentage: this.singleItem[0].greenDeliveries.postalCodePercentage });
        }
        else if (this.singleItem[0].greenDeliveries.postalCodePercentage < 15) {
          // TRA-1571 - parcel is greenway and postal code percentage is less than 15
          this.postalCodeTexts = this.translateService.instant('greenDeliveriesInMyArea.one').replace(/(\{\{)([\w]+)(\}\})/ig, (match, group1, group2, group3) => this.commonService.translateFinal(group2, this.singleItem[0], translationsLatest));
        }
      }
    }
    else if (
      this.singleItem[0].greenDeliveries.postalCodeFound === true &&
      this.singleItem[0].greenDeliveries.shipmentDeliveredGreenWay === false
    ) {
      if (this.singleItem[0].greenDeliveries.postalCodePercentage >= 15) {
        // TRA-1571 - parcel is not greenway and postal code percentage is greater than 15
        this.postalCodeTexts = this.translateService.instant('greenDeliveriesInMyArea.two', { postalCodePercentage: this.singleItem[0].greenDeliveries.postalCodePercentage });
      }
      else if (this.singleItem[0].greenDeliveries.postalCodePercentage < 15) {
        // TRA-1571 - parcel is not greenway and postal code percentage is less than 15
        // do no display the box
        this.isVisibleGreenDelivery = false;
      }
    }
    else {
      // do not display the box
      this.isVisibleGreenDelivery = false;
    }
  }

}
