import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AddressChangeModalComponent } from '../../shared/components/address-change-modal/address-change-modal.component';
import { AddressConfirmModalComponent } from '../../shared/components/address-confirm-modal/address-confirm-modal.component';
import { AddressDialogModalComponent } from '../../shared/components/address-dialog-modal/address-dialog-modal.component';
import { AddressInvalidModalComponent } from '../../shared/components/address-invalid-modal/address-invalid-modal.component';
import { AddressSuggestionModalComponent } from '../../shared/components/address-suggestion-modal/address-suggestion-modal.component';
import { CommonService } from '../services/common.service';
import { ItemsService } from '../services/items.service';
@Injectable({
  providedIn: 'root'
})
export class AddressService {
  public popupData;
  public suggestionAddress;
  constructor(
    public itemsService: ItemsService,
    public commonService: CommonService,
    private readonly dialog: MatDialog,
    private router: Router) { }

  onClickChangeAddress(updateAddress) {
    this.itemsService.changeAddressData(updateAddress).subscribe(res => {
      this.popupData = res;
      if (this.popupData.error) {
        this.openFeedbackDialog({
          msg: 'notpossible',
          itemIdentifier: updateAddress.itemIdentifier
        });
      } else {
        if (this.popupData.isServiceEnabled === false) {
          this.openFeedbackDialog({
            msg: 'nolongerpossible',
            productCategoryName: updateAddress.productCategoryName,
            itemIdentifier: updateAddress.itemIdentifier
          });
          console.log('nolongerpossible');
        } else {
          if (this.popupData.status === true) {
            console.log('success');
            this.openFeedbackDialog({
              msg: 'success',
              updatedAddress: updateAddress,
              itemIdentifier: updateAddress.itemIdentifier
            });
          } else if (this.popupData.status === false && this.popupData.statusCode === 'ERR_04') {
            this.suggestionAddress = this.popupData.data;
            console.log('suggestion for address', this.suggestionAddress);
            if (this.popupData.data && this.popupData.data.length === 1) {
              this.openAddressConfirmModal({
                addressSuggestions: this.suggestionAddress,
                productCategoryName: updateAddress.productCategoryName,
                itemIdentifier: updateAddress.itemIdentifier
              });
            } else if (this.popupData.data && this.popupData.data.length > 1) {
              this.openFeedbackDialogSuggestion({
                addressSuggestions: this.suggestionAddress,
                productCategoryName: updateAddress.productCategoryName,
                itemIdentifier: updateAddress.itemIdentifier
              });
            } else {
              this.openInvalidAddModal({
                productCategoryName: updateAddress.productCategoryName,
                itemIdentifier: updateAddress.itemIdentifier
              });
            }
          } else if (this.popupData && this.popupData.status === false && this.popupData.statusCode === 'ERR_02') {
            this.openFeedbackDialog({
              msg: 'changed',
              productCategoryName: updateAddress.productCategoryName,
              itemIdentifier: updateAddress.itemIdentifier
            });
            console.log('changed');
          } else if (!this.popupData || (this.popupData && this.popupData.status === false && (this.popupData.statusCode === 'ERR_01' || this.popupData.statusCode === 'ERR_03' || this.popupData.statusCode === 'ERR_05'))) {
            this.openFeedbackDialog({
              msg: this.popupData.statusCode === 'ERR_03' ? 'nolongerpossible' : this.popupData.statusCode === 'ERR_05' ? 'pugoPoint' : 'notpossible',
              productCategoryName: updateAddress.productCategoryName,
              itemIdentifier: updateAddress.itemIdentifier,
            });
            console.log('notpossible and change in progress');
          }
        }
      }
      // need to add error handling if postalcde or item is null etc
    });
  }
  retryCallchangeAddress() {
    const storedAddress = JSON.parse(localStorage.getItem('updatedAddress'));
    console.log('storedAddress', storedAddress);
    this.openFeedbackDialogChange(storedAddress);
  }
  openFeedbackDialog(msg) {
    const addressFeedbackDialog = this.dialog.open(AddressChangeModalComponent, {
      width: '584px',
      data: msg,
      disableClose: true,
      maxWidth: '98vw',
      maxHeight: '98vh'
    });
    addressFeedbackDialog.afterClosed().subscribe(result => {
      if (result) {
        if (result.reload) {
          this.router.navigate(['search'], {
            queryParams: { lang: localStorage.getItem('lang'), itemCode: msg.itemIdentifier }
          }).then(() => {
            window.location.reload();
          });
        }
      }
    });
  }
  openFeedbackDialogChange(currentAddress) {
    this.dialog.open(AddressDialogModalComponent, {
      width: '800px',
      data: currentAddress,
      disableClose: true,
      maxWidth: '98vw',
      maxHeight: '98vh'
    });
  }
  openFeedbackDialogSuggestion(dialogData) {
    this.dialog.open(AddressSuggestionModalComponent, {
      width: '584px',
      data: dialogData,
      disableClose: true,
      maxWidth: '98vw',
      maxHeight: '98vh'
    });
  }

  openInvalidAddModal(dialogData) {
    this.dialog.open(AddressInvalidModalComponent, {
      width: '584px',
      data: dialogData,
      disableClose: true,
      maxWidth: '98vw',
      maxHeight: '98vh'
    });
  }

  openAddressConfirmModal(dialogData) {
    this.dialog.open(AddressConfirmModalComponent, {
      width: '584px',
      data: dialogData,
      disableClose: true,
      maxWidth: '98vw',
      maxHeight: '98vh'
    });
  }
}
