import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { CommonService } from '../../../core/services/common.service';
import { ItemsService } from '../../../core/services/items.service';
import { AddressChangeModalComponent } from '../address-change-modal/address-change-modal.component';
import { AddressDialogModalComponent } from '../address-dialog-modal/address-dialog-modal.component';
@Component({
  selector: 'app-delivery-address',
  templateUrl: './delivery-address.component.html',
  styleUrls: ['./delivery-address.component.css']
})
export class DeliveryAddressComponent implements OnInit {
  constructor(
    private readonly itemsService: ItemsService,
    private readonly dialog: MatDialog,
    private readonly commonService: CommonService,
    private activatedRoute: ActivatedRoute) { }

  singleItem; itemIdentifier; postalCode; items; receiverDetails;
  senderDetails; receiverCountry; translations; receiverAddress; senderCountry; senderAddress;
  isVisHidHours: boolean;
  isCssDownArrow: boolean;
  visibilityFlag;
  public visibilityData;
  public popupData;
  public suggestionAddress;
  public currentAddress;
  public passingAddress;
  public productCategoryName;
  ngOnInit() {
    this.isVisHidHours = false;
    this.isCssDownArrow = true;
    this.singleItem = JSON.parse(localStorage.getItem('SelectedItem'));
    this.receiverDetails = this.singleItem[0].receiver;
    this.senderDetails = this.singleItem[0].sender;
    this.productCategoryName = this.getProductCategory();
    this.translations = JSON.parse(localStorage.getItem('translations_' + localStorage.getItem('lang')));
    if (this.receiverDetails && this.receiverDetails.countryCode !== 'BE') { this.receiverCountry = this.translations.countries[this.receiverDetails.countryCode]; }
    if (this.senderDetails && this.senderDetails.countryCode !== 'BE') { this.senderCountry = this.translations.countries[this.senderDetails.countryCode]; }
    if (this.receiverDetails) { this.receiverAddress = this.fullAddress(this.receiverDetails, this.receiverCountry); }
    if (this.receiverDetails && this.singleItem[0].externalId) {
      this.currentAddress = this.receiverDetails;
      this.passingAddress = {
        name: this.currentAddress.name,
        street: this.currentAddress.streetName,
        number: this.currentAddress.streetNumber,
        box: this.currentAddress.box,
        postalCode: this.currentAddress.postcode,
        city: this.currentAddress.municipality,
        country: this.currentAddress.countryCode,
        productCategoryName: this.productCategoryName,
        itemIdentifier: this.singleItem[0].externalId,
        changePrefLink: this.singleItem[0].linkToDeliveryPreferences
      };
      localStorage.setItem('updatedAddress', JSON.stringify(this.passingAddress));
      console.log('external id & calling visibilityapi');
      this.itemsService.getChangeVisiblityData(this.singleItem[0].externalId).subscribe(response => { // need to pass this.singleItem[0].externalId as fn parameter
        this.visibilityData = response;
        console.log('response from changeAddressVisiblity :', this.visibilityData);
        if (this.visibilityData.status === true && this.visibilityData.isServiceEnabled === true) {
          this.visibilityFlag = 'success';
          console.log('success');
        } else if (this.visibilityData && this.visibilityData.status === false && this.visibilityData.statusCode === 'ERR_02') {
          this.visibilityFlag = 'changed';
          console.log('changed');
        } else if (this.visibilityData && this.visibilityData.status === false && this.visibilityData.statusCode === 'ERR_03') {
          this.visibilityFlag = 'inprogress';
          console.log('inprogress');
        } else if (!this.visibilityData || (this.visibilityData && this.visibilityData.status === false && this.visibilityData.statusCode === 'ERR_01')) {
          this.visibilityFlag = 'notpossible'; // api failed or not possible - shows nothing
          console.log('notpossible');
        }
      });
      this.activatedRoute.fragment.subscribe((fragment) => {
        console.log(fragment);
        if (fragment === 'addrchange') {
          this.itemsService.getChangeVisiblityData(this.singleItem[0].externalId).subscribe(response => {
            this.visibilityData = response;
            if (this.visibilityData.status === true && this.visibilityData.isServiceEnabled === true) {
              this.visibilityFlag = 'success';
              this.callChangeAddress();
            }
          });
        }
      });
    }
  }

  callChangeAddress() {
    console.log('passingAddress_1st time', this.passingAddress);
    this.openFeedbackDialogChange(this.passingAddress); // call to open address change modal
  }

  openFeedbackDialog(msg) {
    this.dialog.open(AddressChangeModalComponent, {
      width: '584px',
      data: msg,
      disableClose: true,
      maxWidth: '98vw',
      maxHeight: '98vh'
    });
  }
  openFeedbackDialogChange(passingAddress) {
    this.dialog.open(AddressDialogModalComponent, {
      width: '800px',
      data: passingAddress,
      disableClose: true,
      maxWidth: '98vw',
      maxHeight: '98vh'
    });
  }
  openDirection(lat, long) {
    const url = `${environment.googleMapsLink}${lat},${long}`;
    window.open(url, '_blank');
  }
  isVisableHideHours() {
    this.isVisHidHours = !this.isVisHidHours;
    this.isCssDownArrow = !this.isCssDownArrow;
  }

  fullAddress(address, countryCode) {
    let output = '';
    if (address.street) { output += address.street; }
    if (address.box && address.streetName && address.streetNumber) {
      output += ' / ' + address.box;
    }
    if (address.street) { output += ', '; }
    if (address.postcode) { output += address.postcode + ' '; }
    if (address.municipality) { output += address.municipality + ', '; }
    if (countryCode) { output += countryCode; }
    if (!countryCode) {
      output = output.trim().replace(', ', '<sub>').replace(',', '').replace('<sub>', ', ');
    }
    output = output.toUpperCase();
    return output;
  }

  getProductCategory(): string {
    return this.commonService.getProductCategoryName(this.singleItem[0]);
  }
}
