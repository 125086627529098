import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-static-faq-home',
  templateUrl: './btr-static-faq-home.component.html',
  styleUrls: ['./btr-static-faq-home.component.css']
})
export class BtrHomeStaticFaqComponent implements OnInit {

  buttonLink: string;

  constructor(private readonly traslateService: TranslateService) { }

  ngOnInit() {
    // nothing here
  }

  goToTntFaq() {
    this.buttonLink = this.traslateService.instant('FAQ.allFaqButtonLink');
    window.open(this.buttonLink, '_self');
  }
}
