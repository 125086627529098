<div class="container">
  <div class="row faq-row">
    <div class=" col-lg-12 nopadding-col">
      <h4 class="faq-title text-center" [innerHTML]="'homepage.needHelpText' | translate"></h4>
      <div class="faq-divider"></div>
    </div>
    <div class=" col-lg-12 nopadding-col">
      <ul>
        <li class="faq-li">
          <a href="{{'FAQ.question1_link' | translate }}" target="_blank">
            {{'FAQ.question1' | translate }}<span class="faq-icon pull-right glyphicon glyphicon glyphicon-menu-right"></span>
          </a>
        </li>
        <li class="faq-li">
          <a href="{{'FAQ.question2_link' | translate }}" target="_blank">
            {{'FAQ.question2' | translate }}<span class="faq-icon pull-right glyphicon glyphicon glyphicon-menu-right"></span>
          </a>
        </li>
        <li class="faq-li">
          <a href="{{'FAQ.question3_link' | translate }}" target="_blank">
            {{'FAQ.question3' | translate }}<span class="faq-icon pull-right glyphicon glyphicon glyphicon-menu-right"></span>
          </a>
        </li>
      </ul>
      <div class="custom-submit-button text-center pt20">
        <button id="setIndicateDeliveryPref" (click)="goToTntFaq()">{{'FAQ.allFaqButtonText' | translate }}</button>
      </div>
    </div>
  </div>
</div>
