<div class="custom-address-info row">
  <div class="col-xs-12 col-md-12">
    <div class="row">
      <div class="col-xs-12">
        <p class="deliver-to-pref">{{'delivery_preferences.preferences' | translate}}</p>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12">
        <div class="deliver-to-info">
          <p [innerHTML]="FallbackDeliveryExplanationTitle"></p>
          <div *ngIf="isVisibleSustainabilityPrefBlock">
            <app-sustainability-pref></app-sustainability-pref>
          </div>
          <p [innerHTML]="fallbackInformation"></p>
        </div>
      </div>
    </div>

    <ng-container *ngIf="IsFallbackDeliverySelectedExplanationStatus && securityLevel; then RequestPrefInfo"></ng-container>

    <ng-template #RequestPrefInfo>
      <div class="row" *ngIf="requestedPreferences.safeplaceDelivery && requestedPreferences.safeplaceDelivery.type">
        <div class="custom-safe-parent">
          <div class="col-xs-10 custom-pref-sub-one">
            <p class="custom-pref-one">{{'delivery_preferences.safe_place' | translate}}</p>
            <p class="custom-pref-two">{{safePlace}}</p>
          </div>
          <div class="col-xs-2 custom-pref-sub-two">
            <img src="/assets/images/icon_safePlace.png" />
          </div>
        </div>
      </div>
      <div class="row" *ngIf="showNeighbour==true">
        <div class="custom-neighbour-parent">
          <div class="col-xs-10 custom-pref-sub-one">
            <p class="custom-pref-one">{{'delivery_preferences.neighbour' | translate}}</p>
            <p *ngIf="neighbourAddress" class="custom-pref-two">{{neighbourAddress}}</p>
          </div>
          <div class="col-xs-2 custom-pref-sub-two">
            <img src="/assets/images/icon_neighbour.png" />
          </div>
        </div>
      </div>
      <div class="row" *ngIf="showRerouting==true">
        <div class="custom-pickup-parent direct">
          <div class="col-xs-10 custom-pref-sub-one">
            <p class="custom-pref-one">{{'delivery_preferences.direct_to_pickup_point' | translate}}</p>
            <p *ngIf="singleItem[0].deliveryPoint" class="custom-pref-two">{{reroutingAddress}}</p>
          </div>
          <div class="col-xs-2 custom-pref-sub-two">
            <img src="/assets/images/icon_shop_red RGB.png" />
          </div>
        </div>
      </div>
      <div class="row" *ngIf="showPreferredAvisage==true">
        <div class="custom-pickup-parent">
          <div class="col-xs-10 custom-pref-sub-one">
            <p class="custom-pref-one">{{'delivery_preferences.back_up_pickup_point' | translate}}</p>
            <p *ngIf="singleItem[0].deliveryPoint" class="custom-pref-two">{{preferredAvisageAddress}}</p>
          </div>
          <div class="col-xs-2 custom-pref-sub-two">
            <img src="/assets/images/icon_shop_red RGB.png" />
          </div>
        </div>
      </div>
    </ng-template>

    <ng-container *ngIf="isFallbackButtonVisible && isStreetPresent">
      <div class="row">
        <div class="custom-submit-button">
          <button id="setIndicateDeliveryPref" (click)="goToDeliveryPreferences(singleItem[0].linkToDeliveryPreferences)">{{fallbackButtonText}}</button>
        </div>
      </div>
    </ng-container>
  </div>
</div>
