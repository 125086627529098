<div class="container">
  <div class="row mybpostbanner-row">
    <div class="col-sm-push-6 col-sm-6 col-md-push-6 col-md-6 col-lg-6">
      <h4 class="mybpostapp-title" [innerHTML]="'mybpostAppBanner.HomeTitle' | translate"></h4>
      <div class="mybpostapp-divider"></div>

      <div class="checklist_info">
        <div class="checklist_column1">
          <img src="assets/images/checkmark.png" class="checklist_image" />
        </div>
        <div class="checklist_column2" [innerHTML]="'mybpostAppBanner.Home1' | translate"></div>
      </div>

      <div class="checklist_info">
        <div class="checklist_column1">
          <img src="assets/images/checkmark.png" class="checklist_image" />
        </div>
        <div class="checklist_column2" [innerHTML]="'mybpostAppBanner.Home2' | translate"></div>
      </div>

      <div class="checklist_info">
        <div class="checklist_column1">
          <img src="assets/images/checkmark.png" class="checklist_image" />
        </div>
        <div class="checklist_column2" [innerHTML]="'mybpostAppBanner.Home3' | translate"></div>
      </div>
      <div class="storeLinkDiv">
        <a *ngIf="isIosDeviceDetected" href="{{'mybpostAppBanner.utmLinkHome' | translate}}" target="_blank" class="storeLink storelink_apple_logo"><img
               src="assets/images/mybpostapp-apple.svg"></a>
        <a *ngIf="isAndroidDetected" href="{{'mybpostAppBanner.utmLinkHome' | translate}}" target="_blank"
           class="storeLink storelink_android_logo"><img src="assets/images/mybpostapp-android.svg"></a>
      </div>
    </div>
    <div class="col-sm-pull-6 col-sm-6 col-md-pull-6 col-md-6 col-lg-6 appimage-mt-mobile">
      <img class="img-responsive center-block" src="{{'mybpostAppBanner.imageNameHome' | translate}}"
           alt="mybpost-app-install" />
      <img class="img-responsive absolute-qr hidden-xs hidden-sm" src="{{'mybpostAppBanner.qrHomePageUrl' | translate}}"
           alt="mybpostapp-qrcode" />
    </div>
  </div>
</div>
