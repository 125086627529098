import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AES } from 'crypto-js';
import { Cacheable } from 'ngx-cacheable';
import { Observable, Subject, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ItemsService {
  itemOn: any = {};
  constructor(public http: HttpClient) { }

  private data = new Subject();

  getImage(refid: string) {
    return this.http.get(environment.apiUrl + '/track/asset?refId=' + refid, {
      responseType: 'text'
    });
  }

  @Cacheable()
  getItemByBarcode(itemIdentifier, postalCode, enc = false): Observable<any> {
    itemIdentifier = itemIdentifier.replace(/[^A-Za-z0-9_-\s]/g, '');
    console.log('itemIdentifier => ' + itemIdentifier);
    if (itemIdentifier === '') {
      const err = {
        error: 'InvalidBarcode'
      };
      return of(err);
    }
    if (postalCode) {
      postalCode = postalCode.replace(/[^A-Za-z0-9_\s]/g, '');
    }
    let itemURL = `${environment.apiUrl}/track/items?itemIdentifier=${itemIdentifier}&postalCode=${postalCode}`;
    if (enc) {
      itemURL += `&enc=${enc}`;
    }
    return this.http.get(itemURL)
      .pipe(map(data => {
        return data;
      }));
  }

  @Cacheable()
  getItemsByBarcodeArray(itemIdentifiers: string[]): Observable<any> {
    return this.http.post(environment.apiUrl + '/track/items', { barcodes: itemIdentifiers })
      .pipe(map(data => {
        return data;
      }));
  }

  getTranslations(lang): Observable<any> {
    return this.http.get(environment.apiUrl + '/track/translations?user=fe&lang=' + lang)
      .pipe(map(data => {
        return data;
      }));
  }

  @Cacheable()
  getFaqsInItemsDetail(faqIds, lang): Observable<any> {
    return this.http.post(environment.apiUrl + '/track/faqTags', { lang, faqIds })
      .pipe(map(data => {
        return data;
      }));
  }

  getCallCenterCode(itemPrimaryKey): Observable<any> {
    return this.http.get(environment.apiUrl + '/track/callCenterCode?itemPrimaryKey=' + itemPrimaryKey)
      .pipe(map(data => {
        return data;
      }));
  }

  initiatePayment(itemCode, itemCreationDate, language, url): Observable<any> {
    return this.http.post(environment.apiUrl + '/track/initiatepayment', {
      data: this.encryptJSON(
        {
          itemCode, itemCreationDate, language, url
        })
    })
      .pipe(map(data => {
        return data;
      }));
  }

  finalizePayment(itemCode, itemCreationDate, status, orderId): Observable<any> {
    return this.http.post(environment.apiUrl + '/track/finalizepayment', {
      data: this.encryptJSON(
        {
          itemCode, itemCreationDate, status, orderId
        })
    })
      .pipe(map(data => {
        console.log(data);
        return data;
      }));
  }

  refusePayment(itemCode, itemCreationDate): Observable<any> {
    return this.http.post(environment.apiUrl + '/track/refusepayment', {
      data: this.encryptJSON(
        {
          itemCode, itemCreationDate
        })
    })
      .pipe(map(data => {
        return data;
      }));
  }

  downloadPaymentReceipt(itemIdentifier, itemCreationDate): Observable<any> {
    return this.http.get(environment.apiUrl + '/track/generatereceipt?data=' + encodeURIComponent(this.encryptJSON(
      {
        itemIdentifier, itemCreationDate
      })))
      .pipe(map(data => {
        return data;
      }));
  }

  @Cacheable()
  getParcelPicture(refId): Observable<any> {
    return this.http.get(environment.apiUrl + '/track/fetchimage?imageRefId=' + refId)
      .pipe(map(data => {
        return data;
      }));
  }

  encryptJSON(data) {
    return AES.encrypt(JSON.stringify(data), environment.apiCryptoKey).toString();
  }
  apiData(): Observable<any> {
    return this.data.asObservable();
  }
  changeOnRoundStatus(val) {
    this.data.next(val);
  }
  getItemOnOnRoundStatusByBarcodeData(itemIdentifier: string, postalCode: string) {
    this.getItemOnOnRoundStatusByBarcode(itemIdentifier, postalCode).subscribe(response => {
      if (JSON.stringify(this.itemOn) !== JSON.stringify(response)) {
        this.itemOn = response;
        localStorage.setItem('itemOnroundStatus', this.itemOn);
        this.changeOnRoundStatus(this.itemOn);
      }
    });
  }
  storeData() {
    this.itemOn = localStorage.getItem('itemOnroundStatus');
    this.changeOnRoundStatus(this.itemOn);
  }
  getItemOnOnRoundStatusByBarcode(itemIdentifier: string, postalCode: string): Observable<any> {
    return this.http.get(environment.apiUrl + '/track/itemonroundstatus?itemIdentifier=' + itemIdentifier + '&postalCode=' + postalCode)
      .pipe(map(data => {
        return data;
      }));

  }
  getChangeVisiblityData(itemIdentifier) {
    return this.http.get(environment.apiUrl + '/track/changeaddressvisibility?itemIdentifier=' + itemIdentifier)
      .pipe(map(data => {
        return data;
      }));
  }
  changeAddressData(data) {
    return this.http.post(environment.apiUrl + '/track/changeaddress', {
      data: this.encryptJSON(
        {
          data
        })
    })
      .pipe(map(addressdata => {
        return addressdata;
      }));
  }
}
