import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTableModule } from '@angular/material/table';
import { TranslateModule } from '@ngx-translate/core';
import { RecaptchaModule } from 'ng-recaptcha';
import { AutosizeModule } from 'ngx-autosize';
import { OpenlayerMapComponent } from '../btr-map/map.component';
import { AddressChangeModalComponent } from './components/address-change-modal/address-change-modal.component';
import { AddressConfirmModalComponent } from './components/address-confirm-modal/address-confirm-modal.component';
import { AddressDialogModalComponent } from './components/address-dialog-modal/address-dialog-modal.component';
import { AddressInvalidModalComponent } from './components/address-invalid-modal/address-invalid-modal.component';
import { AddressSuggestionModalComponent } from './components/address-suggestion-modal/address-suggestion-modal.component';
import { BtrBannerDetailComponent } from './components/btr-appbanner-detail/btr-appbanner-detail.component';
import { BtrBannerHomeComponent } from './components/btr-appbanner-home/btr-appbanner-home.component';
import { BtrHomeStaticFaqComponent } from './components/btr-static-faq-home/btr-static-faq-home.component';
import { ChatbotComponent } from './components/chatbot/chatbot.component';
import { CommentComponent } from './components/comment/comment.component';
import { DeliveryAddressComponent } from './components/delivery-address/delivery-address.component';
import { DeliveryPreferencesComponent } from './components/delivery-preferences/delivery-preferences.component';
import { GreenDeliveriesBoxComponent } from './components/green-deliveries-box/green-deliveries-box.component';
import { ItemDetailComponent } from './components/item-detail/item-detail.component';
import { ItemsFaqsComponent } from './components/items-faqs/items-faqs.component';
import { KeyInfoZoneComponent } from './components/key-info-zone/key-info-zone.component';
import { LanguageHeaderComponent } from './components/language-header/language-header.component';
import { MessageBannerComponent } from './components/message-banner/message-banner.component';
import { MultiSearchComponent } from './components/multi-search/multi-search.component';
import { ParcelDetailsComponent } from './components/parcel-details/parcel-details.component';
import { ParcelImagesComponent } from './components/parcel-images/parcel-images.component';
import { PaymentDetailComponent } from './components/payment-detail/payment-detail.component';
import { PaymentFeedbackModalComponent } from './components/payment-feedback-modal/payment-feedback-modal.component';
import { PickupPointDetailsComponent } from './components/pickup-point-details/pickup-point-details.component';
import { SearchSummaryComponent } from './components/search-summary/search-summary.component';
import { SearchComponent } from './components/search/search.component';
import { SecureDeliveryInfoComponent } from './components/secure-delivery-info/secure-delivery-info.component';
import { SignForMeComponent } from './components/sign-for-me/sign-for-me.component';
import { SignatureComponent } from './components/signature/signature.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { SustainabilityBlockComponent } from './components/sustainability-block/sustainabilityblock.component';
import { SustainabilityPrefComponent } from './components/sustainability-pref/sustainabilitypref.component';
import { TntActivityComponent } from './components/tnt-activity/tnt-activity.component';
import { CustomDatePipe } from './pipes/custom-date.pipe';
import { NoSanitizePipe } from './pipes/no-sanitize.pipe';
import { RmDeliveryPreferencesComponent } from './components/rm-delivery-preferences/rm-delivery-preferences.component';

@NgModule({
  declarations: [
    MessageBannerComponent,
    LanguageHeaderComponent,
    SearchComponent,
    MultiSearchComponent,
    SearchSummaryComponent,
    ItemDetailComponent,
    KeyInfoZoneComponent,
    ParcelDetailsComponent,
    TntActivityComponent,
    ItemsFaqsComponent,
    SignatureComponent,
    DeliveryAddressComponent,
    DeliveryPreferencesComponent,
    PickupPointDetailsComponent,
    SpinnerComponent,
    NoSanitizePipe,
    CustomDatePipe,
    CommentComponent,
    PaymentDetailComponent,
    PaymentFeedbackModalComponent,
    ParcelImagesComponent,
    OpenlayerMapComponent,
    AddressChangeModalComponent,
    AddressDialogModalComponent,
    AddressSuggestionModalComponent,
    AddressInvalidModalComponent,
    AddressConfirmModalComponent,
    SignForMeComponent,
    SustainabilityPrefComponent,
    SustainabilityBlockComponent,
    GreenDeliveriesBoxComponent,
    BtrBannerDetailComponent,
    BtrBannerHomeComponent,
    BtrHomeStaticFaqComponent,
    ChatbotComponent,
    SecureDeliveryInfoComponent,
    RmDeliveryPreferencesComponent
  ],
  entryComponents: [
    PaymentFeedbackModalComponent,
    AddressChangeModalComponent,
    AddressDialogModalComponent,
    AddressSuggestionModalComponent,
    AddressInvalidModalComponent,
    AddressConfirmModalComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    RecaptchaModule,
    AutosizeModule,
    MatTableModule,
    MatCheckboxModule,
    ReactiveFormsModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    RecaptchaModule,
    MessageBannerComponent,
    LanguageHeaderComponent,
    SearchComponent,
    MultiSearchComponent,
    SearchSummaryComponent,
    ItemDetailComponent,
    KeyInfoZoneComponent,
    ParcelDetailsComponent,
    TntActivityComponent,
    ItemsFaqsComponent,
    SignatureComponent,
    DeliveryAddressComponent,
    DeliveryPreferencesComponent,
    PickupPointDetailsComponent,
    PaymentFeedbackModalComponent,
    AddressChangeModalComponent,
    AddressDialogModalComponent,
    AddressSuggestionModalComponent,
    AddressInvalidModalComponent,
    AddressConfirmModalComponent,
    SpinnerComponent,
    NoSanitizePipe,
    CustomDatePipe,
    SignForMeComponent,
    BtrBannerDetailComponent,
    BtrBannerHomeComponent,
    BtrHomeStaticFaqComponent,
    ChatbotComponent,
    SecureDeliveryInfoComponent
  ]
})
export class SharedModule { }
