import { Component, OnInit } from '@angular/core';
import { GoogleAnalyticsService } from '../../../core/services/google-analytics.service';

@Component({
  selector: 'app-delivery-preferences',
  templateUrl: './delivery-preferences.component.html',
  styleUrls: ['./delivery-preferences.component.css']
})
export class DeliveryPreferencesComponent implements OnInit {
  singleItem; requestedPreferences; neighbourAddress; reroutingAddress;
  preferredAvisageAddress;
  obj = {
    SAFEPLACE_LIST: {
      GARDEN_SHED: {
        en: 'Garden shed',
        fr: 'Un abri de jardin',
        nl: 'Tuinhuis'
      },
      CARPORT: {
        en: 'Carport',
        fr: 'Un carport',
        nl: 'Carport'
      },
      GARAGE: {
        en: 'Garage',
        fr: 'Un garage',
        nl: 'Garage'
      },
      PORCH: {
        en: 'Porch',
        fr: 'Un abri',
        nl: 'Afdakje'
      },
      OTHER: {
        en: 'Other',
        fr: 'Un autre lieu',
        nl: 'Andere plaats'
      }
    }
  };

  FallbackDeliveryExplanationTitle: string;
  fallbackInformation: string;
  fallbackButtonText: string;
  isStreetPresent = true;
  isFallbackButtonVisible = true;
  isForceExecution = false;
  // isAdvised: boolean = false;
  isAdvised;
  isVisibleSustainabilityPrefBlock = false;
  prefKey: string;
  IsFallbackDeliverySelectedExplanationStatus = false;
  showNeighbour = false;
  showRerouting = false;
  showPreferredAvisage = false;
  language: string;
  safePlace = '';
  localTranslations;
  securityLevel = false;
  isDeliveryPreferenceActive = false;
  showSetPreferenceButton = true;

  constructor(private googleAnalyticsService: GoogleAnalyticsService) { }

  ngOnInit() {

    this.language = localStorage.getItem('lang');
    this.singleItem = JSON.parse(localStorage.getItem('SelectedItem'));
    this.localTranslations = JSON.parse(localStorage.getItem('translations_' + this.language.toLowerCase()));
    this.requestedPreferences = this.singleItem[0].requestedPreferences;
    this.securityLevel = this.singleItem[0].matchSecurityLevel;
    this.isAdvised = this.singleItem[0].isAdvisedItem;
    this.isDeliveryPreferenceActive = this.singleItem[0].isDeliveryPreferenceActive;

    if (this.singleItem[0].preferenceInfo?.hidePreferenceChangeButton) {
      this.isFallbackButtonVisible = false;
    }

    if (this.singleItem[0].actualDeliveryInformation) {
      this.isForceExecution = this.singleItem[0].actualDeliveryInformation.isForcedExecution;
    } else {
      this.isForceExecution = false;
    }

    if (!this.requestedPreferences) {
      if (this.isAdvised) {
        this.fallbackInformation = this.localTranslations.label.FallbackDeliveryExplanationAfterNotification;
        this.fallbackButtonText = this.localTranslations.label.SetFallbackDeliveryPreferences;
      } else {
        this.FallbackDeliveryExplanationTitle = this.localTranslations.label.FallbackDeliveryExplanationTitle;
        this.fallbackInformation = this.localTranslations.label.FallbackDeliveryExplanation;
        this.fallbackButtonText = this.localTranslations.label.SetFallbackDeliveryPreferences;
      }
    } else {
      if (this.singleItem[0].productName === 'bpack XL') {
        this.isFallbackButtonVisible = false;
      } else if (this.isAdvised && this.requestedPreferences && this.isDeliveryPreferenceActive) {
        this.isFallbackButtonVisible = false;
        this.fallbackInformation = this.localTranslations.label.FallbackDeliveryNotExecutedExplanation[this.singleItem[0].deliveryPreferenceType];
        if (this.requestedPreferences.fallbackDeliveryType === 'NEIGHBOUR' || this.requestedPreferences.fallbackDeliveryType === 'SAFEPLACE') {
          if (this.requestedPreferences.fallbackDeliveryNotExecutedReason) {
            this.fallbackInformation = this.fallbackInformation + ' ' + this.localTranslations.preferenceNotExecutedReason[this.requestedPreferences.fallbackDeliveryNotExecutedReason];
          } else { // 'DSC-11843 condition' date of development: 27-01-2020
            if (this.singleItem[0] && this.singleItem[0].events[this.isAdvised - 1] && this.singleItem[0].events[this.isAdvised - 1].key) {
              this.fallbackInformation = this.fallbackInformation + '<br\>' + this.singleItem[0].events[this.isAdvised - 1].key[this.language.toUpperCase()].description + '.';
            }
          }
        }
      } else if (this.requestedPreferences && this.isDeliveryPreferenceActive) {
        this.IsFallbackDeliverySelectedExplanationStatus = true;
        this.fallbackInformation = this.localTranslations.label.FallbackDeliverySelectedExplanation[this.singleItem[0].deliveryPreferenceType]; // Fallback_Delivery_Selected_Explanation
        // As per DSC -  11593 new condition is added. Date: 12-FEB-2020
        /* if(this.singleItem[0].preferenceText && this.singleItem[0].preferenceText.txt[this.language.toUpperCase()]){
             this.fallbackInformation =  this.singleItem[0].preferenceText.txt[this.language.toUpperCase()];
         } */
        // TRA-1027 did not recieved the UAT GO
        // if (this.singleItem[0].deliveryPreferenceType === 'SAFEPLACE' && (this.singleItem[0].deliveryPreferenceRejectCode === 'INCOMPATIBLE_WITH_INSURANCE' || this.singleItem[0].deliveryPreferenceRejectCode === 'DISABLED_BY_SENDER_SAFEPLACE_WITH_SIGNATURE')) { // For TRA-1027
        //   this.fallbackInformation = this.localTranslations.preferenceRejectCode[this.singleItem[0].deliveryPreferenceRejectCode];
        // }
        this.fallbackButtonText = this.localTranslations.delivery_preferences.change_preferences;
        this.showSetPreferenceButton = false;
        if (this.singleItem[0].deliveryPreferenceType === 'FORCED_NEIGHBOUR' || this.singleItem[0].deliveryPreferenceType === 'NEIGHBOUR') {
          this.showNeighbour = true;
          if (this.requestedPreferences.neighbourDelivery && this.requestedPreferences.neighbourDelivery.street) {
            this.neighbourAddress = this.fullAddress(this.requestedPreferences.neighbourDelivery);
          }
        } else if (this.requestedPreferences.safeplaceDelivery) {
          this.getSafePlaceName(this.requestedPreferences.safeplaceDelivery.type, this.language);
        } else if (this.requestedPreferences.reroutingStatus && this.requestedPreferences.reroutingStatus.active === true) {
          this.showRerouting = true;
          this.reroutingAddress = '';
          if (this.singleItem[0].isItemReroutedforProcessing) {
            this.isFallbackButtonVisible = false;
          }
          if (this.singleItem[0].pickupPointName) {
            this.reroutingAddress += this.singleItem[0].pickupPointName;
          }
          if (this.singleItem[0].pickupPointAddress) {
            this.reroutingAddress += (this.reroutingAddress ? ',' : '') + this.singleItem[0].pickupPointAddress;
          }
        } else if (this.requestedPreferences.preferredAvisageStatus && this.requestedPreferences.preferredAvisageStatus.active === true) {
          this.showPreferredAvisage = true;
          this.preferredAvisageAddress = '';
          if (this.singleItem[0].pickupPointName) {
            this.preferredAvisageAddress += this.singleItem[0].pickupPointName;
          }
          if (this.singleItem[0].pickupPointAddress) {
            this.preferredAvisageAddress += (this.preferredAvisageAddress ? ',' : '') + this.singleItem[0].pickupPointAddress;
          }
        }
      } else if (this.requestedPreferences && this.singleItem[0].deliveryPreferenceRejectCode) {
        this.fallbackInformation = this.localTranslations.label.FallbackDeliveryRejectedExplanation[this.singleItem[0].deliveryPreferenceType];
        if (this.localTranslations.preferenceRejectCode && this.localTranslations.preferenceRejectCode[this.singleItem[0].deliveryPreferenceRejectCode]) {
          if (this.fallbackInformation) {
            this.fallbackInformation = this.fallbackInformation + ' ' + this.localTranslations.preferenceRejectCode[this.singleItem[0].deliveryPreferenceRejectCode];
          } else {
            this.fallbackInformation = this.localTranslations.preferenceRejectCode[this.singleItem[0].deliveryPreferenceRejectCode];
          }
        }
        this.fallbackButtonText = this.localTranslations.label.SetFallbackDeliveryPreferencesForRejected;
        this.showSetPreferenceButton = false;
      } else {
        this.FallbackDeliveryExplanationTitle = this.localTranslations.label.FallbackDeliveryExplanationTitle;
        this.fallbackInformation = this.localTranslations.label.FallbackDeliveryExplanation;
        this.fallbackButtonText = this.localTranslations.label.SetFallbackDeliveryPreferences;
      }
    }

    // As per DSC -  11593 new condition is added. Date: 20-FEB-2020
    if (this.singleItem[0].preferenceText && this.singleItem[0].preferenceText.txt[this.language.toUpperCase()]) {
      this.fallbackInformation = this.singleItem[0].preferenceText.txt[this.language.toUpperCase()];
      /*
        if (!this.singleItem[0].showDeliveryPreferences && this.singleItem.preferenceText.action && this.singleItem.preferenceText.action === 0) {
          this.isFallbackButtonVisible = false; // hide button
        } else if (!this.singleItem[0].showDeliveryPreferences && this.singleItem.preferenceText.action && this.singleItem.preferenceText.action === 1) {
          this.fallbackButtonText = this.localTranslations.delivery_preferences.change_preferences;
        }
       */
    }

    if (this.singleItem[0].sustainability !== undefined && this.singleItem[0].sustainability.info.negative) {
      this.isVisibleSustainabilityPrefBlock = true;
    }

    if (this.fallbackButtonText === this.localTranslations.delivery_preferences.change_preferences && this.singleItem[0].receiver && this.singleItem[0].receiver.streetName === undefined && this.singleItem[0].receiver.streetNumber === undefined) {
      this.isStreetPresent = false;
    }
  }

  getSafePlaceName(safePlaceName, language) {
    this.safePlace = '';
    switch (safePlaceName) {
      case 'GARDEN_SHED': {
        if (language === 'fr') { this.safePlace = this.obj.SAFEPLACE_LIST.GARDEN_SHED.fr; } else if (language === 'nl') { this.safePlace = this.obj.SAFEPLACE_LIST.GARDEN_SHED.nl; } else { this.safePlace = this.obj.SAFEPLACE_LIST.GARDEN_SHED.en; }
        break;
      }
      case 'CARPORT': {
        if (language === 'fr') { this.safePlace = this.obj.SAFEPLACE_LIST.CARPORT.fr; } else if (language === 'nl') { this.safePlace = this.obj.SAFEPLACE_LIST.CARPORT.nl; } else { this.safePlace = this.obj.SAFEPLACE_LIST.CARPORT.en; }
        break;
      }
      case 'GARAGE': {
        if (language === 'fr') { this.safePlace = this.obj.SAFEPLACE_LIST.GARAGE.fr; } else if (language === 'nl') { this.safePlace = this.obj.SAFEPLACE_LIST.GARAGE.nl; } else { this.safePlace = this.obj.SAFEPLACE_LIST.GARAGE.en; }
        break;
      }
      case 'PORCH': {
        if (language === 'fr') { this.safePlace = this.obj.SAFEPLACE_LIST.PORCH.fr; } else if (language === 'nl') { this.safePlace = this.obj.SAFEPLACE_LIST.PORCH.nl; } else { this.safePlace = this.obj.SAFEPLACE_LIST.PORCH.en; }
        break;
      }
      default: {
        if (language === 'fr') { this.safePlace = this.obj.SAFEPLACE_LIST.OTHER.fr; } else if (language === 'nl') { this.safePlace = this.obj.SAFEPLACE_LIST.OTHER.nl; } else { this.safePlace = this.obj.SAFEPLACE_LIST.OTHER.en; }
      }
    }
    return this.safePlace;

  }

  fullAddress(address) {
    let output = '';
    if (address.firstName) {
      output += address.firstName + ' ';
    }
    if (address.lastName) {
      output += address.lastName + ', ';
    }
    if (address.street) {
      output += address.street + ' ';
    }
    if (address.streetNumber) {
      output += address.streetNumber;
    }
    if (address.postboxNumber && address.street && address.streetNumber) {
      output += ' / ' + address.postboxNumber;
    }
    if (address.streetNumber) {
      output += ', ';
    }
    if (address.postalCode) {
      output += address.postalCode + ' ';
    }
    if (address.city) {
      output += address.city;
    }
    output = output.replace(/,\s*$/, '');
    output = output.toUpperCase();
    return output;
  }

  goToDeliveryPreferences(url) {
    const buttonTitle = (this.showSetPreferenceButton) ? 'set_preference' : 'change_preference';
    this.googleAnalyticsService.pushEventOnButtonClick(buttonTitle);
    let joinWith = '?';
    const id = url.split('id=');
    if (id[1]) {
      joinWith = '&';
    }
    window.open(url + joinWith + 'language=' + this.language, '_blank');
  }
}
