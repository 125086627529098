<!-- Template to pass additional content for desktop view before the actual key info zone -->
<ng-container *ngTemplateOutlet="additionalDesktopContent"></ng-container>
<!-- Desktop view -->
<div class="container custom_status_parent custom-progress-min-height hidden-xs">
  <div class="col-xs-8">

    <!-- Barcode info info -->
    <div *ngIf="eachItem.displayParcelByBpostStatus && eachItem.searchCode">
      <p class="parceln">{{eachItem.headings.barcode}} {{eachItem.searchCode}} {{'Items_summary.from' | translate}}
        {{eachItem.senderCommercialName}}</p>
    </div>
    <div *ngIf="!eachItem.displayParcelByBpostStatus && eachItem.searchCode">
      <p class="parceln">{{eachItem.headings.barcode}} {{eachItem.searchCode}}</p>
    </div>
    <!-- Step info -->
    <div *ngIf="eachItem.displayCommercialNameStatus">
      <p class="heading">{{eachItem.activeStepMain}} {{'Items_summary.by' | translate}}
        {{eachItem.senderCommercialName}}</p>
    </div>
    <div *ngIf="!eachItem.displayCommercialNameStatus">
      <p class="heading">{{activeStepText}}</p>
    </div>

    <!-- Not yet delivered info -->
    <ng-container *ngIf="!eachItem.isDeliveredStatusActive">
      <!-- Process steps -->
      <div *ngIf="!showNewBar||!eachItem.showStopsandProgressBar">
        <div class="custom-progress w3-light-grey w3-round-xlarge">
          <div *ngFor="let elem of eachItem.processSteps; index as i" class="w3-container w3-blue w3-round-xlarge"
               [ngClass]="{'active' : (eachItem.statusArray[i] !== 'upcoming')}">
          </div>
        </div>
      </div>
      <div *ngIf="showNewBar&&eachItem.showStopsandProgressBar">
        <div id="myProgress">
          <div class="myImg2" style="margin-top: -9px; padding-top: 2px; margin-right: -1.0em;">
            <img src="/assets/images/progressbar/home.png" style="width: 20px;" />
          </div>
          <div id="myBar" [ngStyle]="{'width':percentageValue}">
            <div class="myImg" style="margin-top: -9px; padding-top: 2px; margin-right: -1.0em;">
              <img src="/assets/images/progressbar/truck.png" />
            </div>
          </div>
        </div>
      </div>
      <!-- Available Until-->
      <ng-container *ngIf="eachItem.availableUntillOrEstimatedDateStatus === 'AVAILABLE_UNTIL'">
        <div class="date">
          <p *ngIf="eachItem.latestAvailableDate2" class="estimated-date">{{'Items_summary.available_untill' |
            translate}}</p>
          <p class="delivery-date">{{eachItem.latestAvailableDate2 | translate}}{{applyCommaIfTimeExists}}
            {{eachItem.latestAvailableTime.time}}</p>
        </div>
      </ng-container>
      <!-- Available as From -->
      <ng-container
                    *ngIf="eachItem.availableFromOrEstimatedDateStatus === 'AVAILABLE_FROM' && eachItem.expectedDeliveryDate2">
        <div class="date">
          <p *ngIf="eachItem.expectedDeliveryDate2" class="estimated-date">{{'Items_summary.available_from' |
            translate}}</p>
          <p class="delivery-date">{{eachItem.expectedDeliveryDate2 | translate}} {{eachItem.availableFromTime}}</p>
        </div>
      </ng-container>
      <!-- Estimated Delivery Date -->
      <ng-container>
        <table>
          <tr>
            <td *ngIf="showEstimatedDeliveryDate">
              <p *ngIf="eachItem.ppdDerivationSource === 'SENDER_CALCULATION'">{{'Items_summary.expected_delivery_date_sender' | translate}}</p>
              <p class="estimated-date" *ngIf="eachItem.ppdDerivationSource !== 'SENDER_CALCULATION'">{{'Items_summary.expected_delivery_date' | translate}}</p>
            </td>
            <td *ngIf="showEstimatedDeliveryDate" style="padding-right: 100px;"></td>
            <td>
              <p class="estimated-date"
                 *ngIf="showEstimatedDeliveryDate && eachItem.expectedDeliveryTimeRange && eachItem.expectedDeliveryTimeRange.time1 &&  eachItem.expectedDeliveryTimeRange.time2">
                {{'Items_summary.expected_delivery_time' | translate}}</p>
            </td>
            <td *ngIf="showEstimatedDeliveryDate" style="padding-right: 100px;"></td>
            <td>
              <p class="estimated-date" *ngIf="showStops&&eachItem.showStopsandProgressBar">
                {{'Items_summary.stops_remaining' | translate}}</p>
            </td>
          </tr>
          <tr>
            <td *ngIf="showEstimatedDeliveryDate">
              <p class="DeliveryInfo">{{eachItem.expectedDeliveryDate2 | translate}}</p>
              <p *ngIf="estimatedTimeStatus != null" style="margin-top: 20px;"></p>
            </td>
            <td *ngIf="showEstimatedDeliveryDate" style="padding-right: 100px;"></td>
            <td>
              <div class="info"
                   *ngIf="showEstimatedDeliveryDate && eachItem.expectedDeliveryTimeRange && eachItem.expectedDeliveryTimeRange.time1 &&  eachItem.expectedDeliveryTimeRange.time2">
                <p class="DeliveryInfo" [ngStyle]="estimatedTimeStatus === 'SLOT_UPDATED'? {color: '#0d79ac'}:{}">
                  {{eachItem.expectedDeliveryTimeRange.time1}} - {{eachItem.expectedDeliveryTimeRange.time2}}
                </p>
                <p *ngIf="estimatedTimeStatus != null" class="UpdatedInfo" [ngStyle]="estimatedTimeStatus === 'SLOT_UPDATED'? {color: '#0d79ac'}:{}" style="margin-top: -17px;">{{'longCopy.updated' | translate}} </p>
              </div>
            </td>
            <td *ngIf="showEstimatedDeliveryDate" style="padding-right: 100px;"></td>
            <td *ngIf="showStops&&eachItem.showStopsandProgressBar">
              <p class="DeliveryInfo">{{remainingStops}}</p>
            </td>
          </tr>
        </table>
      </ng-container>
      <!-- Irregularity info -->
      <div class="info">
        <p [ngClass]="{'irregularity-text-key' : eachItem.irregularityToggle}" [innerHTML]=eachItem.textKey></p>
      </div>

      <!-- Secure Delivery info-->
      <ng-container *ngIf="eachItem.hasSecureDeliveryCode">
        <div class="secure-code">
          <img src="/assets/images/secure.png" />
          <p [innerHTML]="'secureDelivery.title' | translate"></p>
        </div>
        <div class="secure-code-info">
          <p [innerHTML]="'secureDelivery.info' | translate"></p>
        </div>
      </ng-container>

      <div class="info" *ngIf="eachItem.extraTextKey">
        <p [ngClass]="{'irregularity-text-key' : eachItem.irregularityToggle}" [innerHTML]=eachItem.extraTextKey></p>
      </div>
      <!-- Signature info -->
      <div class="signature" *ngIf="(eachItem.signatureRequired==true && !eachItem.isDeliveredStatusActive) || (eachItem.proofOfDepositStatus === 'AVAILABLE_IN_PSB' && eachItem.productCategory === 'registeredLetter')">
        <img src="/assets/images/icon_signature.png" />
        <p>{{'signature.signature_required' | translate}}</p>
      </div>
      <!-- Customs payment info -->
      <ng-container
                    *ngIf="!eachItem.codAmount && paymentText!=='' && (eachItem.dutyAmount || paymentText !== '' ) && !eachItem.isDeliveredStatusActive">
        <!-- Customs payment required info -->
        <div class="payment" *ngIf="paymentText!==''">
          <img src="/assets/images/icon_paid.png" />
          <p>{{ paymentText | translate}}</p>
        </div>
        <!-- Customs payment info -->
        <div class="due-payment" *ngIf="showCustomsAmountDue">
          <p>
            <strong>{{'customs.amount_due' | translate}} : &euro;{{eachItem.dutyAmount}}</strong>
          </p>
          <a role="button" data-toggle="collapse" href="expand" aria-expanded="true" aria-controls="expand"
             *ngIf="eachItem.showPaymentRequired">{{'customs.cost_overview' | translate}}</a>
        </div>
      </ng-container>

      <!-- COD payment info -->
      <ng-container *ngIf="eachItem.codAmount!==undefined && !eachItem.isDeliveredStatusActive">
        <!-- COD payment required info -->
        <div class="payment">
          <img src="/assets/images/icon_paid.png" />
          <p>{{'cod.payment_required' | translate}}</p>
        </div>
        <!-- COD payment info -->
        <div class="due-payment">
          <p>
            <strong>{{'cod.amount_due' | translate}} : &euro;{{eachItem.codAmount}}</strong>
            {{'cod.pay_instruction' | translate}}.
          </p>
        </div>
      </ng-container>
    </ng-container>

    <!-- Delivered/Collected info -->
    <ng-container *ngIf="eachItem.isDeliveredStatusActive">
      <ng-container *ngIf="eachItem.DeliveredToInfo !=='HIDE'">
        <!-- Delivered/Collected info -->
        <table>
          <tr>
            <td *ngIf="eachItem.DeliveredToInfo !== 'NOADDRESS'">
              <p class="info">{{eachItem.DeliveredOrCollectedText}}</p>
            </td>
            <td *ngIf="eachItem.DeliveredToInfo !== 'NOADDRESS'" style="padding-right: 40px;"></td>
            <td>
              <p class="info"
                 *ngIf="eachItem.DeliveredOnInfo === 'SHOW' &&eachItem.actualDeliveryInformation.actualDeliveryTime.day">
                {{eachItem.DeliveredOrCollectedonText}}</p>
            </td>
          </tr>
          <tr>
            <td *ngIf="eachItem.DeliveredToInfo !== 'NOADDRESS'">
              <p class="DeliveryInfo">{{eachItem.DeliveredToInfo}}</p>
            </td>
            <td *ngIf="eachItem.DeliveredToInfo !== 'NOADDRESS'" style="padding-right: 40px;"></td>
            <td>
              <div class="info"
                   *ngIf="eachItem.DeliveredOnInfo === 'SHOW'  &&eachItem.actualDeliveryInformation.actualDeliveryTime.day">
                <p class="DeliveryInfo">
                  {{eachItem.actualDeliveryDate}}
                  {{eachItem.actualDeliveryInformation.actualDeliveryTime?.time}}
                </p>
              </div>
            </td>
          </tr>
        </table>
      </ng-container>
      <!-- Delivered / Collected Help Text Key -->
      <ng-container *ngIf="eachItem.showTextKey === 'PICKED_NO'">
        <div class="info">
          <p [ngClass]="{'irregularity-text-key' : eachItem.irregularityToggle}" [innerHTML]=eachItem.textKey></p>
        </div>
        <div class="info" *ngIf="eachItem.extraTextKey">
          <p [ngClass]="{'irregularity-text-key' : eachItem.irregularityToggle}" [innerHTML]=eachItem.extraTextKey></p>
        </div>
      </ng-container>
      <!-- Collected Help Text Key without deliveryPoint -->
      <ng-container *ngIf="eachItem.showTextKey === 'PICKED_YES'">
        <div class="info">
          <p [ngClass]="{'irregularity-text-key' : eachItem.irregularityToggle}" [innerHTML]=eachItem.textKey></p>
        </div>
        <div class="info" *ngIf="eachItem.extraTextKey">
          <p [ngClass]="{'irregularity-text-key' : eachItem.irregularityToggle}" [innerHTML]=eachItem.extraTextKey></p>
        </div>
      </ng-container>
    </ng-container>
  </div>

  <!-- Image -->
  <div class="col-xs-4 status-image-parent-modified">
    <ng-container
                  *ngIf="eachItem.safePlaceImgToShow === 'nosafeplace_imgtoshow'; then statusImageView else safePlaceImageView">
    </ng-container>
  </div>
</div>

<!-- Mobile view -->
<div class="container custom_status_parent_mobile visible-xs">
  <!-- Image -->
  <div class="row">
    <div class="prevent-click">
      <ng-container
                    *ngIf="eachItem.safePlaceImgToShow === 'nosafeplace_imgtoshow'; then statusImageView_Mobile else safePlaceImageView">
      </ng-container>
    </div>
  </div>

  <!-- Template to pass additional content for mobile view -->
  <ng-container *ngTemplateOutlet="additionalMobileContent"></ng-container>

  <!-- Process steps -->
  <ng-container *ngIf="!eachItem.isDeliveredStatusActive">
    <div *ngIf="!showNewBar||!eachItem.showStopsandProgressBar">
      <div class="row">
        <div class="col-xs-12 prevent-click">
          <div class="custom-progress w3-light-grey w3-round-xlarge">
            <div *ngFor="let elem of eachItem.processSteps; index as i" class="w3-container w3-blue w3-round-xlarge"
                 [ngClass]="{'active' : (eachItem.statusArray[i] !== 'upcoming')}"></div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="showNewBar&&eachItem.showStopsandProgressBar">
      <div class="rowBar">
        <div class="col-xs-12 prevent-click">
          <div id="myProgress">
            <div class="myImg2" style="margin-top: -9px; padding-top: 2px; margin-right: -1.0em;">
              <img src="/assets/images/progressbar/home.png" style="width: 20px;" />
            </div>
            <div id="myBar" [ngStyle]="{'width':percentageValue}">
              <div class="myImg" style="margin-top: -9px; padding-top: 2px; margin-right: -1.0em;">
                <img src="/assets/images/progressbar/truck.png" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <div class="row">
    <div class="col-xs-12">
      <div class="custom_status_parent">

        <!-- Barcode info -->
        <div *ngIf="eachItem.displayParcelByBpostStatus && eachItem.searchCode">
          <p class="parceln">{{eachItem.headings.barcode}} {{eachItem.searchCode}} {{'Items_summary.from' | translate}}
            {{eachItem.senderCommercialName}}</p>
        </div>
        <div *ngIf="!eachItem.displayParcelByBpostStatus && eachItem.searchCode">
          <p class="parceln">{{eachItem.headings.barcode}} {{eachItem.searchCode}}</p>
        </div>
        <!-- Step info -->
        <div *ngIf="eachItem.displayCommercialNameStatus">
          <p class="heading">{{eachItem.activeStepMain}} {{'Items_summary.by' | translate}}
            {{eachItem.senderCommercialName}}</p>
        </div>
        <div *ngIf="!eachItem.displayCommercialNameStatus">
          <p class="heading">{{activeStepText}}</p>
        </div>
        <!-- Not yet delivered info -->
        <ng-container *ngIf="!eachItem.isDeliveredStatusActive">
          <!-- Available Until-->
          <ng-container *ngIf="eachItem.availableUntillOrEstimatedDateStatus === 'AVAILABLE_UNTIL'">
            <div class="date">
              <p *ngIf="eachItem.latestAvailableDate2" class="estimated-date"> {{'Items_summary.available_untill' |
                translate}}</p>
              <p class="delivery-date">{{eachItem.latestAvailableDate2 | translate}}{{applyCommaIfTimeExists}}
                {{eachItem.latestAvailableTime.time}}</p>
            </div>
          </ng-container>
          <!-- Available as From -->
          <ng-container
                        *ngIf="eachItem.availableFromOrEstimatedDateStatus === 'AVAILABLE_FROM' && eachItem.expectedDeliveryDate2">
            <div class="date">
              <p *ngIf="eachItem.expectedDeliveryDate2" class="estimated-date"> {{'Items_summary.available_from' |
                translate}}</p>
              <p class="delivery-date">{{eachItem.expectedDeliveryDate2 | translate}} {{eachItem.availableFromTime}}</p>
            </div>
          </ng-container>
          <!-- Estimated Delivery Date -->
          <ng-container>
            <div *ngIf="showEstimatedDeliveryDate" class="date">
              <p class="estimated-date">{{'Items_summary.expected_delivery_date' | translate}}</p>
              <p class="delivery-date">{{eachItem.expectedDeliveryDate2 | translate}}</p>
            </div>
            <div
                 *ngIf="showEstimatedDeliveryDate && eachItem.expectedDeliveryTimeRange && eachItem.expectedDeliveryTimeRange.time1 &&  eachItem.expectedDeliveryTimeRange.time2"
                 class="date">
              <p class="estimated-date"> {{'Items_summary.expected_delivery_time' | translate}}</p>
              <p class="delivery-date" [ngStyle]="estimatedTimeStatus === 'SLOT_UPDATED'? {color: '#0d79ac'}:{}">{{eachItem.expectedDeliveryTimeRange.time1}} -
                {{eachItem.expectedDeliveryTimeRange.time2}} </p>
              <p *ngIf="estimatedTimeStatus != null" class="UpdatedInfo" [ngStyle]="estimatedTimeStatus === 'SLOT_UPDATED'? {color: '#0d79ac'}:{}">
                {{'longCopy.updated' | translate}}
              </p>
            </div>
            <div class="date" *ngIf="showStops&&eachItem.showStopsandProgressBar">
              <p class="estimated-date">{{'Items_summary.stops_remaining' | translate}}</p>
              <p class="delivery-date">{{remainingStops}}</p>
            </div>
          </ng-container>
          <!-- Irregularity info -->
          <div class="info">
            <p [ngClass]="{'irregularity-text-key' : eachItem.irregularityToggle}" [innerHTML]=eachItem.textKey></p>
          </div>
          <div class="info" *ngIf="eachItem.extraTextKey">
            <p [ngClass]="{'irregularity-text-key' : eachItem.irregularityToggle}" [innerHTML]=eachItem.extraTextKey>
            </p>
          </div>
          <!-- Secure Delivery info-->
          <ng-container *ngIf="eachItem.hasSecureDeliveryCode">
            <div class="secure-code">
              <img src="/assets/images/secure.png" />
              <p [innerHTML]="'secureDelivery.title' | translate"></p>
            </div>
            <div class="secure-code-info">
              <p [innerHTML]="'secureDelivery.info' | translate"></p>
            </div>
          </ng-container>
          <!-- Signature info -->
          <div class="signature" *ngIf="eachItem.signatureRequired==true && !eachItem.isDeliveredStatusActive">
            <img src="/assets/images/icon_signature.png" />
            <p>{{'signature.signature_required' | translate}}</p>
          </div>
          <!-- Customs payment info -->
          <ng-container
                        *ngIf="!eachItem.codAmount && paymentText!=='' && (eachItem.dutyAmount || paymentText !== '' ) && !eachItem.isDeliveredStatusActive">
            <!-- Customs payment required info -->
            <div class="payment" *ngIf="paymentText!==''">
              <img src="/assets/images/icon_paid.png" />
              <p>{{ paymentText | translate}}</p>
            </div>
            <!-- Customs payment info -->
            <div class="due-payment" *ngIf="showCustomsAmountDue">
              <p>
                <strong>{{'customs.amount_due' | translate}} : &euro;{{eachItem.dutyAmount}}</strong><br>
              </p>
              <a role="button" data-toggle="collapse" href="expand" aria-expanded="true" aria-controls="expand"
                 *ngIf="eachItem.showPaymentRequired">{{'customs.cost_overview' | translate}}</a>
            </div>
          </ng-container>
          <!-- COD payment info -->
          <ng-container *ngIf="eachItem.codAmount!==undefined && !eachItem.isDeliveredStatusActive">
            <!-- COD payment required info -->
            <div class="payment">
              <img src="/assets/images/icon_paid.png" />
              <p>{{'cod.payment_required' | translate}}</p>
            </div>
            <!-- COD payment info -->
            <div class="due-payment">
              <p>
                <strong>{{'cod.amount_due' | translate}} : &euro;{{eachItem.codAmount}}</strong>
                {{'cod.pay_instruction' | translate}}.
              </p>
            </div>
          </ng-container>
        </ng-container>

        <!-- Delivered Or Collected-->
        <ng-container *ngIf="eachItem.isDeliveredStatusActive">
          <ng-container *ngIf="eachItem.DeliveredToInfo !=='HIDE'">
            <div class="info" *ngIf="eachItem.DeliveredToInfo !== 'NOADDRESS'">
              <p>{{eachItem.DeliveredOrCollectedText}}</p>
            </div>
            <div *ngIf="eachItem.DeliveredToInfo !== 'NOADDRESS'">
              <p class="DeliveryInfo">{{eachItem.DeliveredToInfo}}</p>
            </div>
            <div>
              <p class="info"
                 *ngIf="eachItem.DeliveredOnInfo === 'SHOW' && eachItem.actualDeliveryInformation.actualDeliveryTime.day">
                {{eachItem.DeliveredOrCollectedonText}}</p>
            </div>
            <div class="info"
                 *ngIf="eachItem.DeliveredOnInfo === 'SHOW' && eachItem.actualDeliveryInformation.actualDeliveryTime.day">
              <p class="DeliveryInfo">
                {{eachItem.actualDeliveryDate}}
                {{eachItem.actualDeliveryInformation.actualDeliveryTime?.time}}
              </p>
            </div>
          </ng-container>
          <!-- Delivered / Collected Help Text Key -->
          <ng-container *ngIf="eachItem.showTextKey === 'PICKED_NO'">
            <div class="info">
              <p [ngClass]="{'irregularity-text-key' : eachItem.irregularityToggle}" [innerHTML]=eachItem.textKey></p>
            </div>
            <div class="info" *ngIf="eachItem.extraTextKey">
              <p [ngClass]="{'irregularity-text-key' : eachItem.irregularityToggle}" [innerHTML]=eachItem.extraTextKey>
              </p>
            </div>
          </ng-container>
          <!-- Collected Help Text Key without deliveryPoint -->
          <ng-container *ngIf="eachItem.showTextKey === 'PICKED_YES'">
            <div class="info">
              <p [ngClass]="{'irregularity-text-key' : eachItem.irregularityToggle}" [innerHTML]=eachItem.textKey></p>
            </div>
            <div class="info" *ngIf="eachItem.extraTextKey">
              <p [ngClass]="{'irregularity-text-key' : eachItem.irregularityToggle}" [innerHTML]=eachItem.extraTextKey>
              </p>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<!-- Image templates -->
<ng-template #safePlaceImageView>
  <img class="safePlaceCustom" [src]="eachItem.safePlaceImgToShow">
</ng-template>

<ng-template #statusImageView>
  <ng-container *ngIf="module==='LMG'">
    <img class="status-image-lmg-icon" [src]="eachItem.statusImageToShow" (error)="errorHandler($event)">
  </ng-container>
  <ng-container *ngIf="module==='BTR'">
    <img class="status-image" [src]="eachItem.statusImageToShow" (error)="errorHandler($event)">
  </ng-container>
</ng-template>

<ng-template #statusImageView_Mobile>
  <!-- Hide the status image when needed  -->
  <ng-container *ngIf="!hideStatusImageMobile">
    <img class="status-image-custom-align" [src]="eachItem.statusImageToShow" (error)="errorHandler($event)">
  </ng-container>
</ng-template>
