import { Component, ElementRef, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from '../../../../environments/environment';
import { Item } from '../../models/item';
declare var Microsoft: any;
declare var detectIncognito: () => Promise<any>;

@Component({
  selector: 'app-chatbot',
  templateUrl: './chatbot.component.html',
  styleUrls: ['./chatbot.component.css']
})
export class ChatbotComponent implements OnInit, OnDestroy {

  @Input()
  public item: Item;

  public currentLanguage = localStorage.getItem('lang') || 'fr';
  private firstMessageReceived = false;
  private modalAcceptClicked = false;
  private messagesCount = 0;
  private active = false;
  private timer = 0;
  private translations = {
    service: {
      en: 'About parcels', fr: 'À propos des colis', nl: 'Over pakjes'
    },
    receiver: {
      en: 'Iʼm a receiver', fr: 'Je suis le destinataire', nl: 'Ik ben ontvanger'
    }
  };

  constructor(
    private readonly elementRef: ElementRef,
    private readonly renderer: Renderer2,
    private readonly deviceService: DeviceDetectorService,
  ) { }

  ngOnInit(): void {
    if (this.item && this.item.itemCode && this.item.isChatbotVisible) {
      this.loadChatbotScript();
    }
  }

  ngOnDestroy(): void {
    const chatBotIframe = document.getElementById('Microsoft_Omnichannel_LCWidget_Chat_Iframe_Window');
    if (chatBotIframe) {
      chatBotIframe.parentNode.removeChild(chatBotIframe);
    }
  }

  private loadChatbotScript() {
    const script = this.renderer.createElement('script');
    script.id = 'Microsoft_Omnichannel_LCWidget';
    script.src = environment.chatBot.src;
    script.setAttribute('type', 'text/javascript');
    script.setAttribute('charset', 'utf-8');
    script.setAttribute('data-app-id', environment.chatBot.appId[this.currentLanguage]);
    script.setAttribute('data-lcw-version', environment.chatBot.lcwVersion);
    script.setAttribute('data-org-id', environment.chatBot.orgId);
    script.setAttribute('data-org-url', environment.chatBot.orgUrl);
    script.setAttribute('data-color-override', '#EF2637');
    script.setAttribute('data-suggested-action-layout', 'stacked');
    script.setAttribute('data-font-family-override', 'Verdana; Segoe UI');
    script.setAttribute('v2', '');
    script.setAttribute(
      'data-customization-callback', environment.chatBot.customization[this.currentLanguage]
    );

    if (this.deviceService.isMobile()) {
      // script.setAttribute('data-render-mobile', 'true');
      // script.setAttribute('data-hide-chat-button', 'true');
    }
    script.onload = () => {
      window.addEventListener('lcw:ready', () => this.handleLiveChatReadyEvent());

      window.addEventListener('lcw:error', (errorEvent) => {
        // Handle LiveChat SDK error event
        console.log(errorEvent);
      });

      window.addEventListener('lcw:chatQueued', () => {
        // Handle livechat queued event
        if (
          (this.firstMessageReceived === false) ||
          (this.firstMessageReceived === true && localStorage.getItem('modalAcceptClicked') === 'false')
        ) {
          setTimeout(() => {
            // Small delay to ensure the widget is already loaded and fully opened
            this.loadPrivacyModal();
            if (this.active === false) {
              document.getElementById('privacyMessage' + this.currentLanguage.toUpperCase()).style.display = 'none';
            }
          }, 500); // time in milliseconds
        }
      });

      window.addEventListener('lcw:onClose', () => {
        // Handle the live chat widget close event
        this.firstMessageReceived = false;
        this.messagesCount = 0;
        localStorage.setItem('modalAcceptClicked', 'false');
        localStorage.setItem('modalAcceptNone', 'false');
        document.getElementById('privacyMessage' + this.currentLanguage.toUpperCase()).style.display = 'none';
        this.active = false;
        clearTimeout(this.timer);
        this.timer = 0;
      });

      window.addEventListener('storage', (e) => {
        if (localStorage.getItem('modalAcceptNone') === 'true') {
          document.getElementById('privacyMessage' + this.currentLanguage.toUpperCase()).style.display = 'none';
        }
      });

      window.addEventListener('lcw:chatRetrieved', () => {
        // Handle livechat retrieved event
        this.firstMessageReceived = true;
        if (localStorage.getItem('modalAcceptClicked') === 'true') {
          setTimeout(() => {
            // Small delay to ensure the widget is already loaded and fully opened
            document.getElementById('privacyMessage' + this.currentLanguage.toUpperCase()).style.display = 'none';
            localStorage.setItem('modalAcceptNone', 'true');
          }, 500); // time in milliseconds
        }
      });

      window.addEventListener('lcw:onMessageReceived', (payload) => {
        this.messagesCount = this.messagesCount + 1;
        if (this.messagesCount >= 1 && this.firstMessageReceived === false) {
          this.firstMessageReceived = true;
          if (this.modalAcceptClicked === true) {
            document.getElementById('privacyMessage' + this.currentLanguage.toUpperCase()).style.display = 'none';
            localStorage.setItem('modalAcceptNone', 'true');
          }
        }
      });
    };

    this.renderer.appendChild(this.elementRef.nativeElement, script);
  }

  private getOffset(el: HTMLElement) {
    const rect = el.getBoundingClientRect();
    return {
      left: rect.left + window.scrollX,
      top: rect.top + window.scrollY
    };
  }

  private loadPrivacyModal() {
    // Get the modal
    const modal = document.getElementById('privacyMessage' + this.currentLanguage.toUpperCase());
    const modalAccept = document.getElementById('privacyAccept' + this.currentLanguage.toUpperCase());
    const modalClose = document.getElementById('privacyClose' + this.currentLanguage.toUpperCase());
    const loader = document.getElementById('loaderMessage' + this.currentLanguage.toUpperCase());

    const getModalFullSize = () => {
      modal.style.right = '0px';
      modal.style.bottom = '0px';
      modal.style.width = `${document.getElementById('Microsoft_Omnichannel_LCWidget_Chat_Iframe_Window').offsetWidth}px`;
      modal.style.height = `${document.getElementById('Microsoft_Omnichannel_LCWidget_Chat_Iframe_Window').offsetHeight}px`;
    };

    const getModalNormalSize = () => {
      modal.style.width = `${(document.getElementById('Microsoft_Omnichannel_LCWidget_Chat_Iframe_Window').offsetWidth - 10 + 0.2)}px`;
      modal.style.height = `${(document.getElementById('Microsoft_Omnichannel_LCWidget_Chat_Iframe_Window').offsetHeight - 10 + 0.2)}px`;
    };

    if (((document.getElementById('Microsoft_Omnichannel_LCWidget_Chat_Iframe_Window') as HTMLIFrameElement).width === '100%') &&
      ((document.getElementById('Microsoft_Omnichannel_LCWidget_Chat_Iframe_Window') as HTMLIFrameElement).height === '100%')) {
      getModalFullSize();
    } else {
      getModalNormalSize();
    }

    modal.style.display = 'block';
    loader.style.display = 'none';
    modalAccept.style.display = 'inline';
    modalClose.style.display = 'inline';
    (modal.getElementsByClassName('modal-content') as HTMLCollectionOf<HTMLElement>)[0].style.display = 'block';
    modalAccept.addEventListener('focus', (event) => {
      this.active = true;
    });
    modalAccept.focus();
    this.modalAcceptClicked = false;

    const removeModalContent = () => {
      modalAccept.style.display = 'none';
      modalClose.style.display = 'none';
      loader.style.display = 'inline';
      (modal.getElementsByClassName('modal-content') as HTMLCollectionOf<HTMLElement>)[0].style.display = 'none';
      this.modalAcceptClicked = true;
      localStorage.setItem('modalAcceptClicked', 'true');
      if (this.firstMessageReceived && localStorage.getItem('modalAcceptClicked') === 'true') {
        modal.style.display = 'none';
        localStorage.setItem('modalAcceptNone', 'true');
      } else {
        this.timer = window.setTimeout(() => {
          // Timeout in case 'lcw:onMessageReceived' event is never coming
          modal.style.display = 'none';
          localStorage.setItem('modalAcceptNone', 'true');
        }, 45 * 1000); // time in milliseconds
      }
    };

    const sleep = async (ms) => {
      return new Promise(resolve => setTimeout(resolve, ms));
    };

    const close = async () => {
      // Close the widget
      Microsoft.Omnichannel.LiveChatWidget.SDK.closeChat();
      await sleep(3000);
      // Second close to automatically force close the unnecessary survey
      Microsoft.Omnichannel.LiveChatWidget.SDK.closeChat();
    };

    modalAccept.onclick = () => {
      removeModalContent();
    };

    modalClose.onclick = () => {
      modal.style.display = 'none';
      close();
    };

    detectIncognito().then((detectIncognitoResult) => {
      if ((detectIncognitoResult.browserName === 'Chrome' ||
        detectIncognitoResult.browserName === 'Chromium') &&
        detectIncognitoResult.isPrivate === true) {
        (modal.getElementsByClassName('warning') as HTMLCollectionOf<HTMLElement>)[0].style.display = 'block'; // Displaying warning message if incognito mode in Chrome only
        (modal.getElementsByClassName('button-style') as HTMLCollectionOf<HTMLElement>)[0].style.display = 'none'; // Removing start chat button if incognito mode in Chrome only
        (modal.getElementsByClassName('privacy-1') as HTMLCollectionOf<HTMLElement>)[0].style.display = 'none'; // Removing privacy message 1 if incognito mode in Chrome only
        (modal.getElementsByClassName('privacy-2') as HTMLCollectionOf<HTMLElement>)[0].style.display = 'none'; // Removing privacy message 2 if incognito mode in Chrome only
      }
    });

    let fullSize = false;
    addEventListener('resize', (event) => {
      if (
        ((document.getElementById('Microsoft_Omnichannel_LCWidget_Chat_Iframe_Window') as HTMLIFrameElement).width === '100%') &&
        ((document.getElementById('Microsoft_Omnichannel_LCWidget_Chat_Iframe_Window') as HTMLIFrameElement).height === '100%')
      ) {
        getModalFullSize();
        fullSize = true;
      }
      if (fullSize) {
        getModalFullSize();
      } else {
        getModalNormalSize();
      }
    });
  }

  private handleLiveChatReadyEvent(): void {
    const chatContext: any = {
      service: { value: this.translations.service[this.currentLanguage], isDisplayable: true },
      hasBarcode: { value: true, isDisplayable: true },
      platform: { value: 'Web', isDisplayable: true },
      device: { value: this.deviceService.isMobile() ? 'MOBILE' : 'DESKTOP', isDisplayable: true },
      language: { value: localStorage.getItem('lang') || 'fr', isDisplayable: true },
      hostApplication: { value: 'TNT', isDisplayable: true },
      barcode: { value: this.item.itemCode, isDisplayable: true },
      postalCode: { value: this.item.receiver.postcode, isDisplayable: true },
      senderOrReceiver: {
        value: this.item.externalId ? this.translations.receiver[this.currentLanguage] : '',
        isDisplayable: true
      },
      pingId: { value: '', isDisplayable: true },
      subjectLevel1: { value: '', isDisplayable: true },
      subjectLevel2: { value: '', isDisplayable: true },
      subjectLevel3: { value: '', isDisplayable: true },
      subjectLevel4: { value: '', isDisplayable: true },
      lastName: { value: '', isDisplayable: true },
      firstName: { value: '', isDisplayable: true },
      Name: { value: '', isDisplayable: true },
      Email: { value: '', isDisplayable: true },
      EmailAddress: { value: '', isDisplayable: true },
      VATNumber: { value: '', isDisplayable: true },
      authenticatedCustomer: { value: false, isDisplayable: true },
      receiverEmail: { value: 'null', isDisplayable: true },
      senderEmail: { value: 'null', isDisplayable: true },
      receiverFirstName: { value: '', isDisplayable: true },
      receiverName: { value: '', isDisplayable: true }
    };
    Microsoft.Omnichannel.LiveChatWidget.SDK.setContextProvider(function contextProvider() {
      return chatContext;
    });
  }

  isChatbotReloadRequired() {
    const currentChatbotItemCode = sessionStorage.getItem('currentChatbotItemCode');
    if (this.item.itemCode === currentChatbotItemCode) {
      return false;
    } else {
      sessionStorage.setItem('currentChatbotItemCode', this.item.itemCode);
      return true;
    }
  }

}
