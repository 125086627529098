import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { AddressService } from '../../../core/services/address.service';
import { CommonService } from '../../../core/services/common.service';
import { ItemsService } from '../../../core/services/items.service';
@Component({
  selector: 'app-address-change-modal',
  templateUrl: './address-change-modal.component.html',
  styleUrls: ['./address-change-modal.component.css']
})
export class AddressChangeModalComponent implements OnInit {
  addressStatusText: string;
  addressInfoText: string;
  addressButtonText: string;
  addressButtonTextRetry: string;
  changePreferencesText: string;
  productCategoryName: string;
  isRetry = false;
  isPugoPoint = false;
  isSuccess = false;
  language: string;
  constructor(
    public readonly dialogRef: MatDialogRef<AddressChangeModalComponent>,
    @Inject(MAT_DIALOG_DATA) public readonly data,
    public readonly translateService: TranslateService,
    public itemsService: ItemsService,
    public addressService: AddressService,
    private readonly dialog: MatDialog,
    public commonService: CommonService) { }

  ngOnInit() {
    this.language = localStorage.getItem('lang');
    if (this.data.msg === 'success') {
      this.isSuccess = true;
      this.addressStatusText = this.translateService.instant('address_modal.status.success');
      this.addressInfoText = this.translateService.instant('address_modal.info.success', {
        streetName: this.data.updatedAddress.streetName,
        streetNumber: this.data.updatedAddress.streetNumber,
        box: this.data.updatedAddress.boxNumber ? this.data.updatedAddress.boxNumber + ', ' : '',
        postalCode: this.data.updatedAddress.postalCode,
        municipality: this.data.updatedAddress.municipality,
        productCategoryName: this.data.updatedAddress.productCategoryName
      });
      this.addressButtonText = this.translateService.instant('address_modal.button.success');
    }
    else if (this.data.msg === 'changed') {
      this.addressStatusText = this.translateService.instant('address_modal.status.already_modified');
      this.addressInfoText = this.translateService.instant('address_modal.info.already_modified', { productCategoryName: this.data.productCategoryName });
      this.addressButtonText = this.translateService.instant('address_modal.button.already_modified');
    } else if (this.data.msg === 'notpossible') {
      this.isRetry = true;
      this.addressStatusText = this.translateService.instant('address_modal.status.not_possible');
      this.addressInfoText = this.translateService.instant('address_modal.info.not_possible');
      this.addressButtonText = this.translateService.instant('address_modal.button.not_possible');
      this.addressButtonTextRetry = this.translateService.instant('address_modal.button.retry');
    } else if (this.data.msg === 'nolongerpossible') {
      this.addressStatusText = this.translateService.instant('address_modal.status.nolongerpossible');
      this.addressInfoText = this.translateService.instant('address_modal.info.nolongerpossible', { productCategoryName: this.data.productCategoryName });
      this.addressButtonText = this.translateService.instant('address_modal.button.nolongerpossible');
    } else if (this.data.msg === 'pugoPoint') {
      this.isPugoPoint = true;
      this.addressStatusText = this.translateService.instant('address_modal.status.nolongerpossiblepugo');
      this.addressInfoText = this.translateService.instant('address_modal.info.nolongerpossiblepugotext', { productCategoryName: this.data.productCategoryName });
      this.changePreferencesText = this.translateService.instant('address_modal.button.set_preference');
      this.addressButtonTextRetry = this.translateService.instant('address_modal.button.set_preference_retry');
    }
  }

  closeModal(param, event): void {
    const value = {
      reload: param,
      btnId: event.target.id
    };
    this.dialogRef.close(value);
    // if (this.isSuccess) {
    //   window.location.reload();
    // }
  }

  retry(param, event): void {
    this.addressService.retryCallchangeAddress();
    const value = {
      reload: param,
      btnId: event.target.id
    };
    this.dialogRef.close(value);
  }

  changePreferences(param, event): void {
    const value = {
      reload: param,
      btnId: event.target.id
    };
    this.dialogRef.close(value);
    const changePrefLink = JSON.parse(localStorage.getItem('updatedAddress')).changePrefLink;
    console.log('changePrefLink', changePrefLink);
    let joinWith = '?';
    const id = changePrefLink.split('id=');
    if (id[1]) {
      joinWith = '&';
    }
    window.open(changePrefLink + joinWith + 'language=' + this.language, '_blank');
  }

}
