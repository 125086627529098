import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-secure-delivery-info',
  templateUrl: './secure-delivery-info.component.html',
  styleUrls: ['./secure-delivery-info.component.css']
})
export class SecureDeliveryInfoComponent implements OnInit {

  @Input()
  public visibleBlock = 0;
  public informationContent: any;
  public enableSecureCodeDeliveryBlock: any;

  constructor(
    public readonly translateService: TranslateService,
  ) { }

  ngOnInit(): void {
    this.informationContent = this.translateService.instant(this.visibleBlock === 3 ? 'secureDelivery.infoImgBlock.noPreference' : 'secureDelivery.infoImgBlock.preference');
  }
}
