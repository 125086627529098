import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-rm-delivery-preferences',
  templateUrl: './rm-delivery-preferences.component.html',
  styleUrls: ['./rm-delivery-preferences.component.css']
})
export class RmDeliveryPreferencesComponent implements OnInit {
  buttonLink: string;
  rmTextTranslationLineOne: string;
  rmTextTranslationLineTwo: string;
  constructor(
    private readonly translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.rmTextTranslationLineOne = this.translateService.instant('rm-delivery-preferences.text0');
    this.rmTextTranslationLineTwo = this.translateService.instant('rm-delivery-preferences.text1');
  }

  goToRMPrefereedDeliveryText() {
    this.buttonLink = this.translateService.instant('rm-delivery-preferences.button_link');
    window.open(this.buttonLink, '_blank');
  }

}
