import { Component, ContentChild, Input, OnInit, TemplateRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ItemsService } from '../../../core/services/items.service';
import { ActiveStep } from '../../enums/active-step';
import { TnTModule } from '../../enums/tnt-module';
import { ViewMode } from '../../enums/view-mode';

@Component({
  selector: 'app-key-info-zone',
  templateUrl: './key-info-zone.component.html',
  styleUrls: ['./key-info-zone.component.css']
})
export class KeyInfoZoneComponent implements OnInit {
  @Input()
  public module = TnTModule.BTR; // defaults to BTR

  @Input()
  public viewMode: ViewMode;
  @Input()
  public eachItem: any;

  @Input()
  public hideStatusImageMobile = false;

  public showNewBar;
  public remainingStops;
  public paymentText = '';
  public showCustomsAmountDue = false;
  public applyCommaIfTimeExists = '';
  public showEstimatedDeliveryDate = false;
  dataSubscription: Subscription;
  showStops = false;
  public progressbar: any;
  public percentageValue: any;
  public activeStepText: string;
  public estimatedTimeStatus: string;

  // Template to add addtional info in desktop view
  @ContentChild('additionalDesktopContent', { static: false })
  additionalDesktopContent: TemplateRef<any>;

  // Template to add additional info in mobile view
  @ContentChild('additionalMobileContent', { static: false })
  additionalMobileContent: TemplateRef<any>;

  constructor(public readonly translateService: TranslateService, private itemService: ItemsService) { }

  ngOnInit() {
    this.estimatedTimeStatus = this.eachItem.estimatedTimeStatus ? this.eachItem.estimatedTimeStatus : null;
    this.dataSubscription = this.itemService.apiData().subscribe(data => {
      if (data && data.itemOnRoundStatus && data.itemOnRoundStatus.nrOfStopsUntilTarget && data.itemOnRoundStatus.nrOfStopsUntilTarget[0]) {
        this.remainingStops = data.itemOnRoundStatus.nrOfStopsUntilTarget[0];
        this.showStops = true;
        this.showProgressBar(data);
        this.percentageValue = this.percentageValue + '%';
      } else {
        this.showStops = false;
      }
    });
    // Stop event propagation on click to prevent from parent onClick event being triggered (from onClick in search-summary)
    // This is needed on mobile view, because the image an progress steps are not inside the key info zone
    $('.prevent-click').on('click', (e) => {
      e.stopPropagation();
    });
    jQuery(() => {
      $('a[href = "expand"]').on('click', (event) => {
        event.preventDefault();
        $('html, body').animate({
          scrollTop: $('#costoverview').offset().top
        });
      });
    });
    if (this.eachItem.activeStep.name === ActiveStep.AWAITING && (this.eachItem.processOverview.extendedSubtitle === 'PAID' || this.eachItem.processOverview.extendedSubtitle === 'REFUSED')) {
      this.paymentText = 'customs.payment_processing';
    } else if (this.eachItem.processOverview.extendedSubtitle === 'PAID') {
      if (this.eachItem.customs && this.eachItem.customs.AdditionalDetails && this.eachItem.customs.AdditionalDetails.PaymentChannel &&
        this.eachItem.customs.AdditionalDetails.PaymentChannel === 'POST_STATION') {
        this.paymentText = 'customs.retail_payment_successful';
      } else {
        this.paymentText = 'customs.online_payment_successful';
      }
    } else if (this.eachItem.processOverview.extendedSubtitle === 'EXPIRED') {
      this.paymentText = 'customs.payment_expired';
    } else if (this.eachItem.processOverview.extendedSubtitle === 'REFUSED') {
      this.paymentText = 'customs.payment_refused';
    } else if (this.eachItem.processOverview.extendedSubtitle === 'hide') {
      this.paymentText = '';
    } else if (this.eachItem.activeStep.name === ActiveStep.CHALLENGED) {
      this.paymentText = 'customs.payment_challenged';
    } else if (this.eachItem.processOverview.extendedSubtitle === ActiveStep.INVOICE_MISSING) {
      this.paymentText = 'customs.invoice_missing';
    }
    else if (this.eachItem.processOverview.extendedSubtitle === ActiveStep.INVOICE_RECEIVED) {
      this.paymentText = 'customs.invoice_received';
    }
    else if (this.eachItem.processOverview.extendedSubtitle === ActiveStep.DOCUMENT_RECEIVED) {
      this.paymentText = 'customs.documents_received_for_clearance';
    } else if (this.eachItem.processOverview.extendedSubtitle === ActiveStep.DOCUMENTS_UPLOAD_PERIOD_EXPIRED) {
      this.paymentText = 'customs.documents_upload_period_expired';
    } else if (this.eachItem.activeStep.name === ActiveStep.NO_PAYMENT_REQUIRED) {
      this.paymentText = 'customs.no_payment_required';
    } else if (this.eachItem.showPaymentRequired) {
      this.paymentText = 'customs.payment_required';
      this.showCustomsAmountDue = true;
    } else if (this.eachItem.dutyAmount) {
      this.paymentText = 'cod.payment_required';
      this.showCustomsAmountDue = true;
    }
    if (this.eachItem.latestAvailableTime && this.eachItem.latestAvailableTime.time) {
      this.applyCommaIfTimeExists = ',';
    }
    this.checkEstimatedDeliveryDate();
    this.activeStepText = ((this.eachItem.activeStepMain || '') + ' ' + (this.eachItem.activeStepDetail || '')).replace(' ,', ',');

  }

  // For Status Icon onerror Image
  public errorHandler(event) {
    if (this.module === TnTModule.LMG) {
      event.target.src = '/assets/images/lmg/processing_active.svg';
    } else {
      event.target.src = '/assets/images/processing_active.svg';
    }
  }

  public checkEstimatedDeliveryDate() {
    if (this.eachItem.expectedDeliveryDate2 && this.eachItem.availableFromOrEstimatedDateStatus === 'ESTIMATED') {
      this.showEstimatedDeliveryDate = true;
    }
  }
  private showProgressBar(data) {
    data = data.itemOnRoundStatus.progressUntilTarget[0];
    if (parseFloat(data) > 0 && parseFloat(data) < 1) {
      this.showNewBar = true;
      this.percentageValue = Math.round(parseFloat(data) * 100);
    } else {
      this.showNewBar = false;
      this.percentageValue = data;
    }
  }
}
