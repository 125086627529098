export enum ActiveStep {
  AWAITING = 'awaiting_payment',
  SUCCESSFUL = 'payment_successful',
  EXPIRED = 'payment_expired',
  REFUSED = 'payment_refused',
  CHALLENGED = 'payment_challenged',
  NO_PAYMENT_REQUIRED = 'no_payment_required',
  PREPARE = 'prepare',
  INVOICE_MISSING = 'invoice_missing',
  INVOICE_RECEIVED = 'invoice_received',
  CUSTOMS = 'customs',
  DOCUMENT_RECEIVED = 'documents_received_for_clearance',
  DOCUMENTS_UPLOAD_PERIOD_EXPIRED = 'documents_upload_period_expired',
  CUSTOMS_REFERENCE = 'customs_clearance',
  ON_THE_WAY_TO_YOU = 'ON_THE_WAY_TO_YOU',
  ON_THE_WAY_TO_SENDER = 'ON_THE_WAY_TO_SENDER',
  AVAILABLE_IN_LEAN_LOCKER = 'AVAILABLE_IN_PARCEL_LOCKER',
  PICKEDUP_IN_LEAN_LOCKER = 'PICKED_UP_IN_PARCEL_LOCKER',
  ON_THE_WAY_TO_PARCEL_LOCKER_LEAN = 'ON_THE_WAY_TO_PARCEL_LOCKER'
}
