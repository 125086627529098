<div class="container-fluid main-parent">
  <div class="custom-header-bpost background hidden-xs">
    <div class="container new-logo-alignment">
      <div class="col-xs-6 col-sm-6 col-md-offset-1 col-md-6 col-lg-offset-1 col-lg-6 logo-tablets">
        <div class="custom_breadcrumb_one">
          <a (click)="backToHome()"><img class="logo_header" src="/assets/images/bpost-logo.png"></a>
        </div>
      </div>
    </div>
  </div>
  <app-message-banner></app-message-banner>
  <div class="row">
    <div class="image-container">
      <!-- <div class="row search-fields-parent"> -->
      <div class="col-sm-12 col-md-offset-1 col-md-10 col-lg-offset-1 col-lg-10 hidden-xs">
        <p class="Track-your-shipment">{{'homepage.TrackyourText' | translate }}
          {{'homepage.shipmentText' | translate }}
        </p>
      </div>

      <div class="col-sm-12 col-md-offset-1 col-md-10 col-lg-offset-1 col-lg-10 hidden-xs">
        <app-search [wrongItemIdentifier]="errorItemCode" [wrongPostalCode]="errorPostalCode" (valueChange)='dispalyBanner($event)'></app-search>
        <div *ngIf="!noResultsFound && !postalCodeNotValid">
          <p class="co2-texts" [innerHTML]="'homepage.co2LabelText' | translate"></p>
          <a class="btn btn-transparent" target="_blank" href="{{'homepage.co2ButtonLink' | translate }}">{{'homepage.co2ButtonText' | translate }}</a>
        </div>
        <div class="squares-parent-container">
          <div class="col-lg-3 col-md-3 col-sm-3 squares-parent-remove-padding">
            <a target="_blank" href="{{bpostBE}}{{'homepage.downloadApp' | translate }}">
              <div class="squares-style align-center hand-pointer">
                <img src="/assets/images/mobile-app.svg">
                <p class="text-on-boxes">{{'homepage.downloadAppText' | translate }}</p>
                <img class="icon-on-boxes" src="/assets/images/icon.svg">
              </div>
            </a>
          </div>
          <div class="col-lg-3 col-md-3 col-sm-3 squares-parent-remove-padding">
            <a target="_blank" href="{{trackBpostBE}}{{'homepage.deliveryPref' | translate }}">
              <div class="squares-style align-center hand-pointer">
                <img src="/assets/images/preferences.svg">
                <p class="text-on-boxes">{{'homepage.deliveryPrefText' | translate }}</p>
                <img class="icon-on-boxes" src="/assets/images/icon.svg">
              </div>
            </a>
          </div>
          <div class="col-lg-3 col-md-3 col-sm-3 squares-parent-remove-padding">
            <a target="_blank" href="{{bpostBE}}{{'homepage.findBpost' | translate }}">
              <div class="squares-style align-center hand-pointer">
                <img src="/assets/images/locate.svg">
                <p class="text-on-boxes">{{'homepage.findBpostText' | translate }}</p>
                <img class="icon-on-boxes" src="/assets/images/icon.svg">
              </div>
            </a>
          </div>
          <div class="col-lg-3 col-md-3 col-sm-3 squares-parent-remove-padding">
            <a (click)="goToFAQs()">
              <div class="squares-style align-center hand-pointer">
                <img src="/assets/images/help.svg">
                <p class="text-on-boxes">{{'homepage.needHelpText' | translate }}</p>
                <img class="icon-on-boxes" src="/assets/images/icon.svg">
              </div>
            </a>
          </div>
        </div>
      </div>

      <div class="col-sm-offset-1 col-sm-11 col-md-offset-1 col-md-11 col-lg-offset-1 col-lg-11 visible-xs">
        <p class="Track-your-shipment-mobile">{{'homepage.TrackyourText' | translate }}
          <br>{{'homepage.shipmentText' | translate }}
        </p>
      </div>
      <div class="col-sm-offset-1 col-sm-11 col-md-offset-1 col-md-11 col-lg-offset-1 col-lg-11 visible-xs">
        <p class="co2-texts" [innerHTML]="'homepage.co2LabelText' | translate"></p>
        <a class="btn btn-transparent" target="_blank" href="{{'homepage.co2ButtonLink' | translate }}">{{'homepage.co2ButtonText' | translate }}</a>
      </div>
      <div class="container search-mobile search-fields-parent visible-xs">
        <app-search [wrongItemIdentifier]="errorItemCode" [wrongPostalCode]="errorPostalCode" (valueChange)='dispalyBanner($event)'></app-search>
      </div>
      <div class="visible-xs" style="margin-top:3%;">
        <div class="col-xs-6">
          <a target="_blank" href="{{bpostBE}}{{'homepage.downloadApp' | translate }}">
            <div class="squares-style align-center">
              <img src="/assets/images/mobile-app.svg">
              <p class="text-on-boxes">{{'homepage.downloadAppText' | translate }}</p>
              <img class="icon-on-boxes" src="/assets/images/icon.svg">
            </div>
          </a>
        </div>
        <div class="col-xs-6">
          <a target="_blank" href="{{trackBpostBE}}{{'homepage.deliveryPref' | translate }}">
            <div class="squares-style align-center">
              <img src="/assets/images/preferences.svg">
              <p class="text-on-boxes">{{'homepage.deliveryPrefText' | translate }}</p>
              <img class="icon-on-boxes" src="/assets/images/icon.svg">
            </div>
          </a>
        </div>
        <div class="col-xs-6">
          <a target="_blank" href="{{bpostBE}}{{'homepage.findBpost' | translate }}">
            <div class="squares-style align-center">
              <img src="/assets/images/locate.svg">
              <p class="text-on-boxes">{{'homepage.findBpostText' | translate }}</p>
              <img class="icon-on-boxes" src="/assets/images/icon.svg">
            </div>
          </a>
        </div>
        <div class="col-xs-6">
          <a (click)="goToFAQs()">
            <div class="squares-style align-center">
              <img src="/assets/images/help.svg">
              <p class="text-on-boxes">{{'homepage.needHelpText' | translate }}</p>
              <img class="icon-on-boxes" src="/assets/images/icon.svg">
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
  <app-banner-home></app-banner-home>
  <br />
  <app-static-faq-home></app-static-faq-home>
</div>
