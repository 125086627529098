<div class="container">
  <div class="row mybpostbanner-row">
    <div class="col-md-4 col-relative hidden-xs hidden-sm">
      <img class="img-responsive mybpostappimage-banner center-block" src="{{'mybpostAppBanner.imageName' | translate}}"
           alt="mybpost-app-install" />
    </div>
    <div class="col-md-2 hidden-xs hidden-sm">
      <h3 class="mybpostapp-scan text-center" [innerHTML]="'mybpostAppBanner.scanQr' | translate"></h3>
      <img class="mybpostapp-qrcode img-responsive center-block" src="{{'mybpostAppBanner.qrcodeUrl' | translate}}"
           alt="mybpostapp-qrcode" />
    </div>
    <div class="col-md-6">
      <h4 class="mybpostapp-title" [innerHTML]="'mybpostAppBanner.title' | translate"></h4>
      <div class="mybpostapp-divider"></div>

      <div class="checklist_info">
        <div class="checklist_column1">
          <img src="assets/images/checkmark.png" class="checklist_image" />
        </div>
        <div class="checklist_column2" [innerHTML]="'mybpostAppBanner.1' | translate"></div>
      </div>

      <div class="checklist_info">
        <div class="checklist_column1">
          <img src="assets/images/checkmark.png" class="checklist_image" />
        </div>
        <div class="checklist_column2" [innerHTML]="'mybpostAppBanner.2' | translate"></div>
      </div>

      <div class="checklist_info">
        <div class="checklist_column1">
          <img src="assets/images/checkmark.png" class="checklist_image" />
        </div>
        <div class="checklist_column2" [innerHTML]="'mybpostAppBanner.3' | translate"></div>
      </div>
      <div class="storeLinkDiv">
        <a *ngIf="isIosDeviceDetected" href="{{'mybpostAppBanner.utmLink' | translate}}" target="_blank" class="storeLink storelink_apple_logo"><img
               src="assets/images/mybpostapp-apple.svg"></a>
        <a *ngIf="isAndroidDetected" href="{{'mybpostAppBanner.utmLink' | translate}}" target="_blank"
           class="storeLink storelink_android_logo"><img src="assets/images/mybpostapp-android.svg"></a>
      </div>

    </div>
  </div>
</div>
